export const TextInput = ({
  title,
  state,
  format,
  onChange,
  placeholder = '',
  disabled = false,
}) => {
  return (
    <>
      <h1 className='text-white mt-1'>{title}</h1>
      <input
        disabled={disabled}
        placeholder={placeholder}
        className={`${
          disabled && 'opacity-75'
        } placeholder:italic placeholder:text-gray-200 p-2 bg-[#61CFB8] drop-shadow-sm rounded-[6px] border h-[3rem] my-2 w-full ${
          disabled ? 'border-gray' : 'border-white'
        } text-white focus:outline-none`}
        value={state}
        type={format}
        onChange={onChange}
      />
    </>
  );
};
