import { config } from "../config";

export const fetchMeditationList = async (mediaType) => {
  try {
    let response = await fetch(
      `${config.baseUrl}fetchMeditations?meditation=${mediaType}`
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const createMeditation = async (body) => {
  try {
    let response = await fetch(`${config.baseUrl}createMeditation`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        media: body,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const addAndEditCredits = async (body) => {
  try {
    let response = await fetch(`${config.baseUrl}addAndEditContentCredits`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        credits: body,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const addAndEditQuotes = async (body) => {
  try {
    let response = await fetch(`${config.baseUrl}addAndEditQuotes`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        quotes: body,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const addAndEditBanners = async (body) => {
  try {
    let response = await fetch(`${config.baseUrl}addAndEditBanner`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        banner: body,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const fetchContentCredits = async () => {
  try {
    let response = await fetch(`${config.baseUrl}getContentCredits`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const getQuotes = async () => {
  try {
    let response = await fetch(`${config.baseUrl}fetchQuotes`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};
export const getBanners = async () => {
  try {
    let response = await fetch(`${config.baseUrl}fetchBanners`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const getFeedbacks = async (quoteId) => {
  try {
    let response = await fetch(
      `${config.baseUrl}getFeedBacks?quote_id=${quoteId}`
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const addAndEditListingData = async (body) => {
  try {
    let response = await fetch(`${config.baseUrl}addAndEditListing`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        listing: body,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const addAndEditCounselorData = async (body) => {
  try {
    let response = await fetch(`${config.baseUrl}addAndEditCounselor`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        counselor: body,
      }),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const getResourceListings = async () => {
  try {
    let response = await fetch(`${config.baseUrl}fetchResourceListings`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};

export const getResourceCounselor = async () => {
  try {
    let response = await fetch(`${config.baseUrl}fetchResourceCounselors`);
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error.message);
  }
};
