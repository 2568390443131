export const styles = {
  //dashboard
  backgroundImg: (poster) => {
    return {
      backgroundImage: `url(${poster})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderRadius: 10,
    };
  },
  transparentBackground: {
    backgroundColor: 'rgba(0,0,0,0.6)',
  },
  menuWidth: {
    width: '10%',
  },
  addNewMeditationContainer: {
    width: '90%',
  },
  tabsButton: (active) => {
    return {
      backgroundColor: active ? '#42525F' : '#F1F1F1',
      color: active ? '#fff' : '#42525F',
    };
  },
  quoteBackground: (live, index) => {
    return {
      backgroundColor: live
        ? '#FF0000'
        : index % 2 !== 0
        ? '#A3F3E3'
        : '#282B2B',
    };
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  darkOverlay: {
    backgroundColor: 'rgba(255,255,255,0.3)',
  },
  imageOverlay: {
    backgroundColor: 'rgba(0,0,0,0.3)',
  },

  logoutContainer: {
    height: '10%',
  },
  creditView: {
    width: '96%',
  },
  rightsReserved: {
    height: '5%',
  },
  sideBarButtonContainer: {
    height: '55%',
  },
  logoContainer: {
    height: '30%',
  },
  //AddQuotes.jsx
  addQuoteBgColor: {
    backgroundColor: 'rgba(0,0,0,0.7)',
  },

  //Counsellors.jsx
  counsellorsOverlay: (iteration) => {
    return {
      backgroundColor:
        iteration % 2 !== 0
          ? 'rgba(255,255,255,0.62)'
          : 'rgba(255,255,255,0.2)',
    };
  },
  roundedView: {
    backgroundColor: 'rgba(255,255,255,0.62)',
  },
  //listing.jsx
  roundedIconView: {
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  //feedback.jsx
  feedbackContainer: {
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  //banner.jsx
  fromNowBgColor: {
    backgroundColor: 'rgba(250,250,250,0.2)',
  },
};
