import {styles} from '../styles/styles';
import {FiEdit} from 'react-icons/fi';
import {BiHide, BiShow} from 'react-icons/bi';
import {IoRibbonSharp} from 'react-icons/io5';
import {selectIconColor} from '../utliz';

export const Listing = ({res, index, edit, hideListing}) => {
  return (
    <div className='h-[14rem] w-full flex'>
      <div className='h-4/5 pl-4 '>
        <div
          style={styles.quoteBackground(false, index)}
          className='h-full shadow-lg w-[17.5rem] rounded-3xl relative'
        >
          <div
            style={styles.overlay}
            className='h-full w-full rounded-3xl grid'
          >
            <div className='rounded-3xl h-full w-full pt-5 px-6'>
              <div className='flex place-content-center'>
                <div
                  style={styles.quoteBackground(false, index)}
                  className='shadow-lg h-[4rem] w-[4rem] rounded-full -top-7 absolute'
                >
                  <div
                    style={styles.roundedIconView}
                    className='shadow-lg h-[4rem] w-[4rem] rounded-full absolute grid place-content-center'
                  >
                    <IoRibbonSharp color={selectIconColor(index)} size={38} />
                  </div>
                </div>
              </div>

              <p className='text-center text-lg text-white font-semibold mt-6 line-clamp-1'>
                {res.name}
              </p>
              <div className='flex justify-center'>
                <p className=' text-center text-xs text-white mt-4 max-w-[10rem] line-clamp-2'>
                  {res.session}
                </p>
              </div>
            </div>
            <div
              style={styles.counsellorsOverlay(index)}
              className='flex justify-end w-full h-[2.8rem] absolute bottom-0 rounded-b-[26px] rounded-t-[24px]'
            >
              {res.hide ? (
                <div
                  className='h-full grid place-content-center px-1 cursor-pointer'
                  onClick={() => hideListing(res)}
                >
                  <BiHide color={selectIconColor(index)} size={24} />
                </div>
              ) : (
                <div
                  className='h-full grid place-content-center px-1 cursor-pointer'
                  onClick={() => hideListing(res)}
                >
                  <BiShow color={selectIconColor(index)} size={26} />
                </div>
              )}
              <div
                className='h-full grid place-content-center px-4 cursor-pointer'
                onClick={() => edit(res)}
              >
                <FiEdit color={selectIconColor(index)} size={22} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
