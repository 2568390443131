import {isEmpty} from 'lodash';
import moment from 'moment';
import React from 'react';
import {CONTENT_CREDITS, DATE_FORMAT, THOUGHT_OF_THE_DAY} from '../constants';
import {styles} from '../styles/styles';

export const Feedbacks = ({show, feedbacks, closeModal}) => {
  return (
    <>
      {show ? (
        <div
          style={styles.feedbackContainer}
          className='overflow-y-auto overflow-x-hidden fixed grid place-content-center z-50 w-full md:inset-0 h-modal md:h-full'
        >
          <div className=' p-4 w-96 h-full md:h-auto'>
            <div className='relative bg-white rounded-lg shadow dark:bg-[#758A9B]'>
              <button
                onClick={closeModal}
                type='button'
                className='absolute top-3 right-2.5 text-black dark:text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
                data-modal-toggle='authentication-modal'
              >
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'></path>
                </svg>
                <span className='sr-only'>{CONTENT_CREDITS.closeModal}</span>
              </button>
              <div className='py-6 px-6 lg:px-8'>
                <h3 className='mb-4 text-xl font-medium text-gray-900 dark:text-white'>
                  {'Feedbacks'}
                </h3>
                {isEmpty(feedbacks) ? (
                  <p className='dark:text-white'>
                    {THOUGHT_OF_THE_DAY.noFeedbacks}
                  </p>
                ) : (
                  <div className='h-[30rem] overflow-y-auto'>
                    {!isEmpty(feedbacks) &&
                      feedbacks.map((res, index) => {
                        return (
                          <div
                            key={index}
                            className='my-5 bg-gray-100 p-4 rounded-md dark:bg-[#425768]'
                          >
                            <p className='text-sm font-semibold dark:text-white'>
                              {moment(res.created_at * 1000).format(
                                DATE_FORMAT.american
                              )}
                            </p>
                            <p className='dark:text-white mt-4 text-sm'>
                              {res.feedback}
                            </p>
                            <p className='dark:text-white text-end text-sm mt-2 font-semibold'>
                              {THOUGHT_OF_THE_DAY.impactful}:{' '}
                              {res.impactful ? '👍' : '👎'}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                )}
                <button
                  className='text-base flex place-content-center mt-10 w-full mx-auto text-white focus:ring-4 focus:outline-none font-medium rounded-lg px-5 py-2.5 text-center dark:bg-[#425768] bg-[#45535E]'
                  onClick={closeModal}
                >
                  <p className=''>{'Close'}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
