import moment from 'moment';
import {useState, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {
  DASHBOARD_LABELS,
  LABELS,
  LOGIN_LABELS,
  LOGOUT_LABELS,
  NAVIGATION_NAMES,
  SIDEBAR_ACTIVE_NAME,
  SIDEBAR_SCREENS,
} from '../constants';
import {AuthContext} from '../context/AuthContext';
import {styles} from '../styles/styles';
import {Alert} from './Alert';
import {SidebarButton} from './SidebarButton';

export default function Sidebar({open, toggleMenu}) {
  const [showModal, toggleModal] = useState(false);
  const {dispatch} = useContext(AuthContext);
  const [activeScreen, setActiveScreen] = useState(SIDEBAR_ACTIVE_NAME.name);
  const year = moment().year();
  const navigation = useNavigate();

  const [alertTitle, setAlertTitle] = useState('');
  const [alertMessage, setAlertMessage] = useState('');

  const handleShow = () => {
    toggleModal(true);
  };

  const handleClose = () => {
    toggleModal(false);
  };

  const logout = async () => {
    try {
      handleClose();
      dispatch({type: 'LOGOUT', payload: null});
      navigation('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  const logoutPopup = () => {
    setAlertTitle(LOGOUT_LABELS.logout);
    setAlertMessage(LOGOUT_LABELS.areYouSure);
    handleShow();
  };

  const switchPage = (activeName, screen) => {
    setActiveScreen(activeName);
    SIDEBAR_ACTIVE_NAME.name = activeName;
    navigation(`/${screen}`);
    toggleMenu();
  };

  useEffect(() => {
    setTimeout(() => {
      setActiveScreen(SIDEBAR_ACTIVE_NAME.name);
    }, 0);
  }, []);

  return (
    <div
      className={`absolute md:relative h-screen z-[10] bg-[#242727] w-screen md:w-96 ${
        !open ? 'sm: hidden md:block' : 'block'
      } `}
    >
      {showModal && (
        <Alert
          handleClose={handleClose}
          title={alertTitle}
          message={alertMessage}
          cancelText={LABELS.cancel}
          okayText={LOGOUT_LABELS.logout}
          onPress={logout}
          secondary={true}
        />
      )}
      <div style={styles.logoContainer}>
        <div className=' h-[5rem] w-full'>
          <div className='h-full  flex justify-between md:place-content-center'>
            <img
              alt={LOGIN_LABELS.logo}
              src={require('../images/dark-logo.png')}
              className='h-[3.2rem] w-36 self-center ml-10 md:ml-0'
            />

            <img
              className='h-[1.6rem] ml-5 md:hidden cursor-pointer self-center mr-4'
              src={require('../images/svg/close.svg').default}
              alt={DASHBOARD_LABELS.boxSvg}
              onClick={toggleMenu}
            />
          </div>
        </div>
        <div className='h-[2rem] w-full my-14  flex justify-center'>
          <h2 className='text-xl text-white font-bold tracking-[.37em]'>
            {DASHBOARD_LABELS.adminPanel}
          </h2>
        </div>
      </div>
      <div style={styles.sideBarButtonContainer}>
        <SidebarButton
          activeScreen={activeScreen}
          sidebarScreen={SIDEBAR_SCREENS.dashboard}
          switchPage={switchPage}
          navigationName={NAVIGATION_NAMES.dashboard}
        />
        <SidebarButton
          activeScreen={activeScreen}
          sidebarScreen={SIDEBAR_SCREENS.credits}
          switchPage={switchPage}
          navigationName={NAVIGATION_NAMES.contentCredits}
        />
        <SidebarButton
          activeScreen={activeScreen}
          sidebarScreen={SIDEBAR_SCREENS.thoughtOfTheDay}
          switchPage={switchPage}
          navigationName={NAVIGATION_NAMES.thoughtOfTheDay}
        />
        <SidebarButton
          activeScreen={activeScreen}
          sidebarScreen={SIDEBAR_SCREENS.homeScreenBanner}
          switchPage={switchPage}
          navigationName={NAVIGATION_NAMES.homeScreenBanner}
        />
        <SidebarButton
          activeScreen={activeScreen}
          sidebarScreen={SIDEBAR_SCREENS.resources}
          switchPage={switchPage}
          navigationName={NAVIGATION_NAMES.resources}
        />
      </div>
      <div style={styles.logoutContainer}>
        <div
          className='flex h-[3em] pl-3  place-items-center cursor-pointer align-bottom'
          onClick={logoutPopup}
        >
          <img
            className='h-[1.5rem]'
            src={require('../images/svg/logout.svg').default}
            alt={DASHBOARD_LABELS.boxSvg}
          />
          <h2 className='text-[#9E9E9E] ml-4 text-lg'>
            {DASHBOARD_LABELS.logout}
          </h2>
        </div>
      </div>
      <div style={styles.rightsReserved} className='grid place-content-center'>
        <p className='text-[#B3B3B3]'>© eMOTy {year} all rights reserved</p>
      </div>
    </div>
  );
}
