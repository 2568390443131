export const LOGIN_LABELS = {
  login: 'Login',
  password: 'Password',
  forgotPassword: 'Forgot Password?',
  logo: 'logo',
  email: 'email',
  wrongEmailOrPassword: 'Email or password cannot be empty!',
  invalidEmail: 'Invalid email',
};

export const LABELS = {
  emptyString: '',
  emptyArray: [],
  login: 'Login',
  resize: 'resize',
  somethingWentWrong: 'Something went wrong!',
  cancel: 'Cancel',
  mediaType: 'Media Type',
  type: 'Type',
  zero: '0',
  secs: 'secs',
  mins: 'mins',
  edit: 'Edit',
  noItem: 'No items found',
};

export const DASHBOARD_LABELS = {
  dashboard: 'Dashboard',
  adminPanel: 'ADMIN PANEL',
  boxSvg: 'boxSvg',
  logout: 'Logout',
  plus: 'plus',
  globe: 'globe',
  createNewMeditation: 'Create New Meditation',
};

export const SVG_LABELS = {
  whiteUser: 'white_user',
  user: 'user',
  clock: 'clock',
  feedback: 'feedback',
  edit: 'edit',
};

export const TEXTINPUT_FORMAT = {
  email: 'email',
  password: 'password',
  text: 'text',
  url: 'url',
};

export const CREATE_MEDITATION = {
  title: 'Title',
  category: 'Category',
  image: 'Image',
  media: 'Media',
  uploadImage: 'Upload image',
  uploadVideoOrAudio: 'Upload ',
  createMeditation: 'Create Meditation',
  newMeditation: 'New Meditation',
  heightBreakPoint: 700,
  duration: 'Media duration',
  contentCredits: 'Content credit',
  creditsUrl: 'Site of content credits',
  urlPlaceholder: 'e.g. http://audionautix.com/',
};

export const DASHBOARD_TABS = [
  {
    name: 'Guided',
    id: 1,
    active: true,
  },
  {
    name: 'Music',
    id: 2,
    active: false,
  },
  {
    name: 'Mantras',
    id: 3,
    active: false,
  },
];

export const TIMEZONES = [
  {
    name: 'Eastern Standard Time',
    value: {
      name: 'America/Detroit',
      label: '(GMT-5:00) Eastern Time',
      offset: -5,
      abbrev: 'EST',
      altName: 'Eastern Standard Time',
    },
    id: 1,
  },

  {
    name: 'Central Standard Time',
    value: {
      name: 'America/Belize',
      label: '(GMT-6:00) Central America',
      offset: -6,
      abbrev: 'CST',
      altName: 'Central Standard Time',
    },
    id: 2,
  },

  {
    name: 'Mountain Standard Time',
    value: {
      name: 'America/Boise',
      label: '(GMT-7:00) Mountain Time',
      offset: -7,
      abbrev: 'MST',
      altName: 'Mountain Standard Time',
    },
    id: 3,
  },

  {
    name: 'Pacific Standard Time',
    value: {
      name: 'America/Los_Angeles',
      label: '(GMT-8:00) Pacific Time',
      offset: -8,
      abbrev: 'PST',
      altName: 'Pacific Standard Time',
    },
    id: 4,
  },
];

export const RESOURCES_TABS = [
  {
    name: 'Counselors',
    id: 1,
    active: true,
  },
  {
    name: 'Listing',
    id: 2,
    active: false,
  },
];

export const TYPE = [
  {
    id: 1,
    name: 'Audio',
  },
  {
    id: 2,
    name: 'Video',
  },
];

export const MEDIA_TYPE = {
  recovery: 'recovery',
  quote: 'quotes',
};

export const LOGOUT_LABELS = {
  logout: 'Logout',
  areYouSure: 'Are you sure you want to logout?',
};

export const VALIDATION_MESSAGES = {
  bothFilesRequired: 'Both image and media file required!',
  titleRequired: 'Title required',
  selectMediaType: 'Kindly select Media type',
  selectType: 'Kindly select the type of the media.',
  addCredits: 'Kindly add the content credits for this media.',
  addCreditsUrl: 'Kindly add the reference url for the media',
  //Thought of the day
  nameRequired: 'Kindly add the author name.',
  dateRequired: 'Date required.',
  quoteRequired: 'Kindly add author quote',
  messageRequired: 'Kindly add the message of the quote',
  invalidUrl: 'Invalid Url.',
  emptyUrl: 'URL cannot be empty.',
  imageRequired: 'Kindly add a square quote image',
  sessionName: 'Add session name',
  title: 'Title is required',
  // Counselor
  nameRequiredCounselor: 'Kindly add the counselor Name',
  emailRequired: 'Kindly add the counselor Email',
  educationRequired: 'Education field is required',
  specializationRequired: 'Specialization field is required',
  invalidEmail: 'Invalid Email',
};

export const SIDEBAR_SCREENS = {
  dashboard: 'Dashboard',
  credits: 'Content credits',
  thoughtOfTheDay: 'Thought of the day',
  homeScreenBanner: 'Home screen Banner',
  resources: 'Resources',
};

export const NAVIGATION_NAMES = {
  dashboard: 'dashboard',
  contentCredits: 'credits',
  createMeditation: 'create-meditation',
  thoughtOfTheDay: 'thought-of-day',
  homeScreenBanner: 'home-screen-banner',
  resources: 'resources',
};

export const SIDEBAR_ACTIVE_NAME = {
  name: 'Dashboard',
};

export const CONTENT_CREDITS = {
  contentCredits: 'Content Credits',
  closeModal: 'Close modal',
  editCredits: 'Edit Credit',
  credit: 'Credit',
  url: 'Url',
  creditError: "Credit's content cannot be emoty.",
  creditUrlError: 'Kindly provide a url for this content credit.',
};

export const ERROR_MESSAGES = {
  somethingWentWrong: 'Something went wrong kindly try again later.',
};

export const THOUGHT_OF_THE_DAY = {
  createQuote: 'Create quote',
  quotesList: 'Quotes',
  name: 'Name',
  date: 'Created Date',
  quote: 'Quote',
  message: 'Message',
  create: 'CREATE',
  newQuote: 'NEW QUOTE',
  editQuote: 'EDIT QUOTE',
  edit: 'EDIT',
  liveQuote: 'Live this quote',
  noFeedbacks: 'No feedbacks given yet!',
  impactful: 'Impactful',
  imageSize: 'File is too big, maximum image size can be 15mb',
  image: 'Image',
  clickToUpload: 'Click to upload',
  selectSquareImage: 'Select square image (e.g. 600x600 etc)',
  live: 'Live',
  title: 'Title',
  url: 'URL',
  verbage:
    'Content will only be Live when this button is selected and only a single content can be live at one time.',
};

export const DATE_FORMAT = {
  american: 'MM/DD/YYYY',
};

export const HOME_SCREEN_BANNER = {
  title: 'Title',
  link: 'Link',
  liveBanner: 'Live this banner',
  edit: 'Edit',
  create: 'Create',
  editBanner: 'Edit Banner',
  addBanner: 'Add Banner',
};

export const RESOURCES = {
  addCounselor: 'Add Counselor',
  addListing: 'Add Listing',
  editListing: 'Edit Listing',
  editCounselor: 'Edit Counselor',
  name: 'Name',
  session: 'Session',
  url: 'Url',
  iconBgColor: 'Icon background color',
  icon: 'Icon',
  hideCounselor: 'Hide counselor',
  hideListing: 'Hide listing',
  education: 'Education',
  specialization: 'Specialization',
  email: 'Email',
  timezone: 'Timezone',
  mode: 'Online',
};

export const COLORS = {
  black: 'black',
  white: 'white',
  aquamarine: '#24bc9c',
};
