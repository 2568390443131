import { SVG_LABELS } from "../constants";
import { styles } from "../styles/styles";
import { FiEdit } from "react-icons/fi";
import { BiHide, BiShow } from "react-icons/bi";
import { selectIconColor } from "../utliz";

export const Counsellors = ({ res, index, edit, hideCounselor }) => {
  return (
    <div className="h-[22rem] w-full flex">
      <div className="h-4/5 pl-4 ">
        <div
          style={styles.quoteBackground(false, index)}
          className="h-full shadow-lg w-[17.5rem] rounded-3xl relative"
        >
          <div
            style={styles.overlay}
            className="h-full w-full rounded-3xl grid"
          >
            <div className="rounded-3xl h-full w-full pt-5 px-6">
              <div
                style={styles.roundedView}
                className="flex place-content-center mx-auto h-[4.8rem] w-[4.8rem] rounded-full"
              >
                <img
                  className="my-auto h-[2.5rem]"
                  src={
                    index % 2 !== 0
                      ? require("../images/svg/user.svg").default
                      : require("../images/svg/black_user.svg").default
                  }
                  alt={SVG_LABELS.clock}
                />
              </div>
              <p className="text-center text-lg text-white font-semibold mt-2">
                {res.name}
              </p>
              <p className="text-center text-sm text-white mt-2">
                {res.education}
              </p>
              <div className="flex justify-center">
                <p className=" text-center text-xs text-white mt-4 max-w-[10rem] ">
                  {res.specialization}
                </p>
              </div>
            </div>
            <div
              style={styles.counsellorsOverlay(index)}
              className="flex justify-end w-full h-[2.8rem] absolute bottom-0 rounded-b-[26px] rounded-t-[24px]"
            >
              {res.hide ? (
                <div
                  className="h-full grid place-content-center px-1 cursor-pointer"
                  onClick={() => hideCounselor(res)}
                >
                  <BiHide color={selectIconColor(index)} size={24} />
                </div>
              ) : (
                <div
                  className="h-full grid place-content-center px-1 cursor-pointer"
                  onClick={() => hideCounselor(res)}
                >
                  <BiShow color={selectIconColor(index)} size={26} />
                </div>
              )}
              <div
                className="h-full grid place-content-center px-4 cursor-pointer"
                onClick={() => edit(res)}
              >
                <FiEdit color={selectIconColor(index)} size={22} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
