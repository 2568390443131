import React from 'react';
import {useEffect} from 'react';
import {useState} from 'react';
import {
  CONTENT_CREDITS,
  DASHBOARD_LABELS,
  DATE_FORMAT,
  LABELS,
  THOUGHT_OF_THE_DAY,
} from '../constants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {isEmpty, isNull} from 'lodash';
import moment from 'moment';
import {styles} from '../styles/styles';
import {activeTabStyle} from '../utliz';

export const AddQuotes = ({
  show,
  closeModal,
  edit = false,
  name = LABELS.emptyString,
  setName,
  date = LABELS.emptyString,
  setDate,
  message = LABELS.emptyString,
  setMessage,
  quote = LABELS.emptyString,
  setQuote,
  editQuote,
  addQuote,
  live,
  setLive,
  activeTab,
  setCurrentTab,
  selectImage,
  quoteImage = null,
  title = LABELS.emptyString,
  setTitle,
  url = LABELS.emptyString,
  setUrl,
}) => {
  const [wordCount, setWordCount] = useState(0);

  useEffect(() => {
    setWordCount(message.length);
  }, [message]);

  return (
    <>
      {show ? (
        <div
          style={styles.addQuoteBgColor}
          className='overflow-y-auto overflow-x-hidden fixed grid place-content-center z-50 w-full md:inset-0 h-modal md:h-full'
        >
          <div className='p-4 w-96 h-full md:h-auto'>
            <div className='relative bg-white rounded-lg shadow dark:bg-[#758A9B]'>
              <button
                onClick={closeModal}
                type='button'
                className='absolute top-3 right-2.5 text-black dark:text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
                data-modal-toggle='authentication-modal'
              >
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'></path>
                </svg>
                <span className='sr-only'>{CONTENT_CREDITS.closeModal}</span>
              </button>
              <div className='py-6 px-6 lg:px-8'>
                <h3 className='mb-4 text-xl font-medium text-gray-900 dark:text-white'>
                  {edit
                    ? THOUGHT_OF_THE_DAY.editQuote
                    : THOUGHT_OF_THE_DAY.newQuote}
                </h3>
                <ul className='flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400'>
                  <li
                    className='cursor-pointer w-3/6'
                    onClick={() => setCurrentTab(true)}
                  >
                    <button
                      disabled={edit && !activeTab}
                      className={`${
                        edit && !activeTab && 'cursor-not-allowed'
                      } w-full inline-block py-3 px-4 rounded-lg ${activeTabStyle(
                        activeTab
                      )}`}
                    >
                      {THOUGHT_OF_THE_DAY.quote}
                    </button>
                  </li>
                  <li
                    className='cursor-pointer w-3/6'
                    onClick={() => setCurrentTab(false)}
                  >
                    <button
                      disabled={edit && activeTab}
                      className={`${
                        edit && activeTab && 'cursor-not-allowed'
                      } w-full inline-block py-3 px-4 rounded-lg ${activeTabStyle(
                        !activeTab
                      )}`}
                    >
                      {THOUGHT_OF_THE_DAY.image}
                    </button>
                  </li>
                </ul>
                {activeTab ? (
                  <div className='my-4'>
                    <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                      {THOUGHT_OF_THE_DAY.name}
                    </label>
                    <input
                      type='text'
                      className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                ) : (
                  <div className='flex justify-center items-center w-full mt-4'>
                    {isNull(quoteImage) ? (
                      <label className='flex flex-col justify-center items-center w-full h-[12.5rem] bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer dark:hover:bg-bray-800 dark:bg-[#45535E] hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                        <div className='flex flex-col justify-center items-center pt-5 pb-6'>
                          <svg
                            aria-hidden='true'
                            className='mb-3 w-10 h-10 text-gray-400'
                            fill='none'
                            stroke='currentColor'
                            viewBox='0 0 24 24'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              strokeWidth='2'
                              d='M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12'
                            ></path>
                          </svg>
                          <p className='mb-2 text-sm text-gray-500 dark:text-gray-400'>
                            <span className='font-semibold'>
                              {THOUGHT_OF_THE_DAY.clickToUpload}
                            </span>
                          </p>
                          <p className='text-xs text-gray-500 dark:text-gray-400'>
                            {THOUGHT_OF_THE_DAY.selectSquareImage}
                          </p>
                        </div>
                        <input
                          id='dropzone-file'
                          type='file'
                          className='hidden'
                          name='image'
                          accept={'image/*'}
                          onChange={selectImage}
                        />
                      </label>
                    ) : (
                      <label className='flex flex-col justify-center items-center w-full h-[12.5rem] bg-gray-50 rounded-lg border-2 border-gray-300  cursor-pointer dark:hover:bg-bray-800 dark:bg-[#45535E] hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600'>
                        <img
                          src={`${quoteImage}`}
                          alt={'Quote'}
                          className='h-full w-full rounded-lg'
                        />
                        <input
                          id='dropzone-file'
                          type='file'
                          className='hidden'
                          name='image'
                          accept={'image/*'}
                          onChange={selectImage}
                        />
                      </label>
                    )}
                  </div>
                )}
                <div className='my-4'>
                  <label className='inline-flex relative items-center cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={live}
                      id='default-toggle'
                      className='sr-only peer'
                      onChange={(e) => setLive(e.target.checked)}
                    />
                    <div className="w-11 h-6 bg-gray-200  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                    <span className='ml-3 text-sm font-medium text-gray-900 dark:text-white'>
                      {THOUGHT_OF_THE_DAY.liveQuote}
                    </span>
                  </label>
                </div>
                <p className='text-[10px] -mt-4 font-semibold'>
                  {THOUGHT_OF_THE_DAY.verbage}
                </p>

                {!activeTab && (
                  <>
                    <div className='my-4'>
                      <label className='blockmb-2 text-sm font-medium text-gray-900 dark:text-white'>
                        {THOUGHT_OF_THE_DAY.title}
                      </label>
                      <input
                        type='text'
                        className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                        required
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                  </>
                )}

                <div className='my-4'>
                  <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                    {THOUGHT_OF_THE_DAY.date}
                  </label>
                  <DatePicker
                    className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                    selected={isEmpty(date) ? new Date() : new Date(date)}
                    minDate={new Date()}
                    onChange={(date) =>
                      setDate(moment(date).format(DATE_FORMAT.american))
                    }
                  />
                </div>

                {!activeTab && (
                  <>
                    <div className='mt-2'>
                      <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                        {THOUGHT_OF_THE_DAY.url} (optional)
                      </label>
                      <input
                        type='text'
                        className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                        required
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                  </>
                )}

                {activeTab && (
                  <>
                    <div className='my-4'>
                      <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                        {THOUGHT_OF_THE_DAY.quote}
                      </label>
                      <input
                        type='text'
                        className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                        required
                        value={quote}
                        onChange={(e) => setQuote(e.target.value)}
                      />
                    </div>
                    <div className='my-4'>
                      <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                        {THOUGHT_OF_THE_DAY.message}
                      </label>
                      <textarea
                        type='text'
                        rows={4}
                        maxLength={1000}
                        className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                        required
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                          setWordCount(e.target.value.length);
                        }}
                      />
                    </div>
                    <p className='text-right -mt-3 text-xs text-white'>{`${wordCount}/1000`}</p>
                  </>
                )}

                <button
                  className='text-base flex place-content-center my-4 w-4/5 mx-auto text-white focus:ring-4 focus:outline-none font-medium rounded-lg px-5 py-2.5 text-center dark:bg-[#45535E] bg-[#45535E]'
                  onClick={edit ? editQuote : addQuote}
                >
                  <p className='ml-8'>
                    {edit ? THOUGHT_OF_THE_DAY.edit : THOUGHT_OF_THE_DAY.create}
                  </p>
                  <img
                    className='h-[1rem] self-center ml-8'
                    src={require('../images/svg/plusIcon.svg').default}
                    alt={DASHBOARD_LABELS.boxSvg}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
