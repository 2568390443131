import { LOGIN_LABELS } from '../constants';

export const EmotyLogoHeader = () => {
  return (
    <div className='h-16 sm:h-20 grid place-items-center '>
      <img
        alt={LOGIN_LABELS.logo}
        src={require('../images/emoty-recovery-logo.png')}
        className='h-[2.6rem] w-28 sm:h-[3.2rem] sm:w-36 place-items-center'
      />
    </div>
  );
};
