import {useState, useEffect} from 'react';
import {EmotyLogoHeader} from '../components/EmotyLogoHeader';
import {TextInput} from '../components/TextInput';
import {
  CREATE_MEDITATION,
  DASHBOARD_TABS,
  LABELS,
  MEDIA_TYPE,
  TEXTINPUT_FORMAT,
  TYPE,
  VALIDATION_MESSAGES,
} from '../constants';
import {Dropdown} from '../components/Dropdown';
import {useRef} from 'react';
import {first, isEmpty, isNull} from 'lodash';
import {addAndEditCredits, createMeditation} from '../services';
import {v4 as uuidv4} from 'uuid';
import {ref, getDownloadURL, uploadBytes} from 'firebase/storage';
import {storage} from '../firebase-config';
import {Loader} from '../components/Loader';
import {useNavigate} from 'react-router-dom';
import {checkValidUrl, resizeFile, toHHMMSS} from '../utliz';
window.URL = window.URL || window.webkitURL;

export default function CreateMeditation() {
  const [title, setTitle] = useState(LABELS.emptyString);
  const [credits, setCredits] = useState(LABELS.emptyString);
  const [creditsUrl, setCreditsUrl] = useState(LABELS.emptyString);
  const [loader, setLoader] = useState(false);
  const [duration, setDuration] = useState(LABELS.emptyString);
  const [mediaTypeTitle, setMediaTypeTitle] = useState(LABELS.mediaType);
  const [typeTitle, setTypeTitle] = useState(LABELS.type);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const inputPosterRef = useRef();
  const inputMediaRef = useRef();
  const [posterName, setPosterName] = useState(LABELS.emptyString);
  const [mediaName, setMediaName] = useState(LABELS.emptyString);
  const [posterFile, setPosterFile] = useState(null);
  const [mediaFile, setMediaFile] = useState(null);
  const navigate = useNavigate();
  const [mediaType, setMediaType] = useState('.mp3');

  var myVideos = [];

  const detectSize = () => {
    setScreenHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener(LABELS.resize, detectSize);

    return () => {
      window.removeEventListener(LABELS.resize, detectSize);
    };
  }, [screenHeight]);

  const selectMediaType = (type) => {
    setMediaTypeTitle(type);
  };

  const selectType = (type) => {
    setTypeTitle(type);
    setMediaType(type === 'Audio' ? '.mp3' : 'video/*');
  };

  const validateFields = () => {
    let validate = true;
    if (isNull(mediaFile) || isNull(posterFile)) {
      alert(VALIDATION_MESSAGES.bothFilesRequired);
      validate = false;
    } else if (isEmpty(title.trim())) {
      alert(VALIDATION_MESSAGES.titleRequired);
      validate = false;
    } else if (isEmpty(creditsUrl)) {
      alert(VALIDATION_MESSAGES.emptyUrl);
      validate = false;
    } else if (!checkValidUrl(creditsUrl)) {
      alert(VALIDATION_MESSAGES.invalidUrl);
      validate = false;
    } else if (isEmpty(credits.trim())) {
      alert(VALIDATION_MESSAGES.addCredits);
      validate = false;
    } else if (isEmpty(creditsUrl.trim())) {
      alert(VALIDATION_MESSAGES.addCreditsUrl);
      validate = false;
    } else if (mediaTypeTitle === LABELS.mediaType) {
      alert(VALIDATION_MESSAGES.selectMediaType);
      validate = false;
    } else if (typeTitle === LABELS.type) {
      alert(VALIDATION_MESSAGES.selectType);
      validate = false;
    }
    return validate;
  };

  const createnewMediation = async () => {
    try {
      let permission = validateFields();
      if (permission) {
        setLoader(true);
        let thumbnailPhoto = await resizeFile(posterFile);
        const [posterUrl, posterThumbnailUrl, mediaUrl] = await Promise.all([
          uploadPoster(),
          uploadPosterThumbnail(thumbnailPhoto),
          uploadMediaUrl(thumbnailPhoto),
        ]);
        let data = {
          title,
          code: LABELS.emptyString,
          time: duration,
          mediaType: mediaTypeTitle.toLowerCase(),
          type: typeTitle.toLowerCase(),
          poster: posterUrl,
          thumbnail: posterThumbnailUrl,
          videoUrl: mediaUrl,
        };
        let mediaCredit = {
          name: credits,
          url: creditsUrl,
        };
        const [response] = await Promise.all([
          createMeditation(data),
          addAndEditCredits(mediaCredit),
        ]);
        response.success ? navigate(-1) : alert(response.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const uploadPoster = async () => {
    try {
      let storageType = `${MEDIA_TYPE.recovery}`;
      const uniqueId = uuidv4();
      let posterRef = `${storageType}/${uniqueId}-${posterName}`;
      if (!isNull(posterFile)) {
        const posterReference = ref(storage, posterRef);
        uploadBytes(posterReference, posterFile);
        let snapshot = await uploadBytes(posterReference, posterFile);
        let downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
      }
    } catch (error) {
      console.log('Uploading photo error ', error.message);
    }
  };

  const uploadPosterThumbnail = async (file) => {
    try {
      let storageType = `${MEDIA_TYPE.recovery}`;
      const uniqueId = uuidv4();
      let posterRef = `${storageType}/Thumbnail-${uniqueId}-${posterName}`;
      if (!isNull(file)) {
        const posterReference = ref(storage, posterRef);
        uploadBytes(posterReference, file);
        let snapshot = await uploadBytes(posterReference, file);
        let downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
      }
    } catch (error) {
      console.log('Uploading thumbnail photo error ', error.message);
    }
  };

  const uploadMediaUrl = async () => {
    try {
      let storageType = `${MEDIA_TYPE.recovery}`;
      const uniqueId = uuidv4();
      let mediaRef = `${storageType}/${uniqueId}-${mediaName}`;
      if (!isNull(mediaFile)) {
        const posterReference = ref(storage, mediaRef);
        uploadBytes(posterReference, mediaFile);
        let snapshot = await uploadBytes(posterReference, mediaFile);
        let downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
      }
    } catch (error) {
      console.log('Uploading media error ', error.message);
    }
  };

  const imageBrowser = (event) => {
    if (event.target.files[0].size > 1597152) {
      alert('File is too big, maximum image size can be 15mb');
      return;
    }
    const imageFile = first(event.target.files);
    setPosterFile(imageFile);
    setPosterName(imageFile.name);
  };

  const audioBrowser = (event) => {
    setFileInfo(first(event.target.files));
    const audioFile = first(event.target.files);
    setMediaFile(audioFile);
    setMediaName(audioFile.name);
  };

  const setFileInfo = (file) => {
    myVideos.push(file);
    var video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      var duration = video.duration;
      let time = toHHMMSS(parseInt(duration));
      setDuration(time);
    };
    video.src = URL.createObjectURL(file);
  };

  const onImageButtonClicked = () => {
    inputPosterRef.current.click();
  };

  const onMediaButtonClicked = () => {
    inputMediaRef.current.click();
  };

  return (
    <div className='h-screen'>
      <EmotyLogoHeader />
      <Loader loading={loader} />
      <div className='h-full'>
        <div
          className={`w-[20rem] sm:w-[26rem] bg-[#61CEB8] rounded-[8px] drop-shadow-lg p-8 px-8 sm:px-12 mx-auto ${
            screenHeight > CREATE_MEDITATION.heightBreakPoint ? 'mt-14' : 'mt-4'
          }`}
        >
          <div className='h-full'>
            <p className='text-center text-white  text-lg font-medium uppercase'>
              {CREATE_MEDITATION.newMeditation}
            </p>
            <TextInput
              title={CREATE_MEDITATION.title}
              state={title}
              format={TEXTINPUT_FORMAT.text}
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextInput
              title={CREATE_MEDITATION.contentCredits}
              state={credits}
              format={TEXTINPUT_FORMAT.text}
              onChange={(e) => setCredits(e.target.value)}
            />
            <TextInput
              title={CREATE_MEDITATION.creditsUrl}
              state={creditsUrl}
              format={TEXTINPUT_FORMAT.url}
              onChange={(e) => setCreditsUrl(e.target.value)}
              placeholder={CREATE_MEDITATION.urlPlaceholder}
            />
            <div className='flex justify-between mt-4'>
              <Dropdown
                width={'36'}
                title={mediaTypeTitle}
                onPress={selectMediaType}
                list={DASHBOARD_TABS}
              />
              <Dropdown title={typeTitle} onPress={selectType} list={TYPE} />
            </div>

            <input
              className='hidden'
              ref={inputPosterRef}
              type='file'
              name='image'
              accept={'image/*'}
              onChange={imageBrowser}
            />
            <input
              className='hidden'
              ref={inputMediaRef}
              type='file'
              name='audio'
              accept={mediaType}
              onChange={audioBrowser}
            />
            <h1 className='text-white my-3'>{CREATE_MEDITATION.image}</h1>
            <button
              className='flex place-items-center justify-center bg-[#24BB9C] h-[2.8rem] p-2 rounded-[8px] w-full text-white drop-shadow-lg text-[14px] uppercase'
              onClick={onImageButtonClicked}
            >
              <img
                className='h-[0.9rem] self-center m-[4px] mx-3'
                src={require('../images/svg/upload.svg').default}
                alt={'upload'}
              />
              <p className='w-3/4 overflow-hidden truncate'>
                {isEmpty(posterName)
                  ? CREATE_MEDITATION.uploadImage
                  : posterName}
              </p>
            </button>
            <h1 className='text-white my-3'>{CREATE_MEDITATION.media}</h1>
            <button
              className='flex place-items-center justify-center bg-[#24BB9C] h-[2.8rem] p-2 rounded-[8px] w-full text-white drop-shadow-lg text-[14px] uppercase'
              onClick={onMediaButtonClicked}
            >
              <img
                className='h-[0.9rem] self-center m-[4px] mx-3'
                src={require('../images/svg/upload.svg').default}
                alt={'upload'}
              />
              <p className='w-3/4 overflow-hidden truncate'>
                {isEmpty(mediaName)
                  ? CREATE_MEDITATION.uploadVideoOrAudio
                  : mediaName}
              </p>
            </button>
            <button
              className='bg-[#45535E] h-[2.8rem] mt-8 p-2 rounded-[8px] w-full text-white drop-shadow-lg text-[14px] uppercase'
              onClick={createnewMediation}
            >
              {CREATE_MEDITATION.createMeditation}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
