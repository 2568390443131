import {isEmpty, isNil, isNull} from 'lodash';
import {useEffect} from 'react';
import {useState} from 'react';
import {EditCredits} from '../components/EditCredits';
import {Loader} from '../components/Loader';
import Sidebar from '../components/Sidebar';
import {
  CONTENT_CREDITS,
  DASHBOARD_LABELS,
  ERROR_MESSAGES,
  LABELS,
  SIDEBAR_ACTIVE_NAME,
  SIDEBAR_SCREENS,
  VALIDATION_MESSAGES,
} from '../constants';
import {addAndEditCredits, fetchContentCredits} from '../services';
import {styles} from '../styles/styles';
import {checkForLinkBreak, checkValidUrl} from '../utliz';

function Credits() {
  const [credits, setCredits] = useState([]);
  const [loader, setLoader] = useState(true);
  const [editModal, showEditModal] = useState(true);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [selectedContentName, setSelectedContentName] = useState(
    LABELS.emptyString
  );
  const [selectedContentUrl, setSelectedContentUrl] = useState(
    LABELS.emptyString
  );
  const toggleMenu = () => {
    setOpenSideBar((currentState) => !currentState);
  };

  const getCredits = async () => {
    try {
      let response = await fetchContentCredits();
      if (!isNil(response)) {
        setCredits(response);
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const hideModal = () => {
    showEditModal(false);
  };

  const toggleEditModal = (item) => {
    showEditModal(true);
    setSelectedContent(item);
    setSelectedContentName(item.name);
    setSelectedContentUrl(item.url);
  };

  const editCredit = async () => {
    try {
      if (isEmpty(selectedContentName.trim())) {
        alert(CONTENT_CREDITS.creditError);
      } else if (isEmpty(selectedContentUrl)) {
        alert(VALIDATION_MESSAGES.emptyUrl);
      } else if (!checkValidUrl(selectedContentUrl)) {
        alert(VALIDATION_MESSAGES.invalidUrl);
      } else if (isEmpty(selectedContentUrl.trim())) {
        alert(CONTENT_CREDITS.creditUrlError);
      } else {
        setLoader(true);
        let body = {
          name: selectedContentName,
          url: selectedContentUrl,
          id: selectedContent.id,
        };
        hideModal();
        let response = await addAndEditCredits(body);
        if (response.success) {
          getCredits();
        } else {
          alert(ERROR_MESSAGES.somethingWentWrong);
          setLoader(false);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    SIDEBAR_ACTIVE_NAME.name = SIDEBAR_SCREENS.credits;
    getCredits();
  }, []);

  return (
    <div className='overflow-hidden h-screen flex'>
      <Sidebar open={openSideBar} toggleMenu={toggleMenu} />
      <Loader loading={loader} marginLeft={'96'} />
      {!isNull(selectedContent) && (
        <EditCredits
          show={editModal}
          closeModal={hideModal}
          selectedContentName={selectedContentName}
          selectedContentUrl={selectedContentUrl}
          onChange={setSelectedContentName}
          onChangeUrl={setSelectedContentUrl}
          onPress={editCredit}
        />
      )}
      <div className='w-full px-[20px] md:px-[40px]'>
        <div className='mt-10 overflow-y-auto container h-full pb-10 '>
          <div style={styles.menuWidth} className='grid place-content-center'>
            <img
              className='h-[1.6rem] md:hidden cursor-pointer'
              src={require('../images/svg/menu.svg').default}
              alt={DASHBOARD_LABELS.boxSvg}
              onClick={() => setOpenSideBar((currentState) => !currentState)}
            />
          </div>
          <p className='text-2xl font-semibold py-4'>
            {CONTENT_CREDITS.contentCredits}
          </p>
          {credits.map((res, index) => {
            return (
              <div
                key={index}
                className='flex align-middle my-3 bg-gray-200 rounded-md p-4 container'
              >
                <div style={styles.creditView}>
                  <p className='font-medium'>{checkForLinkBreak(res.name)}</p>

                  <a className='text-blue-600' href={res.url}>
                    {res.url}
                  </a>
                </div>
                <div
                  className='place-content-center grid cursor-pointer'
                  onClick={() => toggleEditModal(res)}
                >
                  <img
                    className='h-[1.2rem] self-center m-[4px] mx-3'
                    src={require('../images/svg/edit.svg').default}
                    alt={DASHBOARD_LABELS.boxSvg}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default Credits;
