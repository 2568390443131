import {isNil, isUndefined} from 'lodash';
import moment from 'moment';
import {LABELS, SVG_LABELS, THOUGHT_OF_THE_DAY} from '../constants';
import {styles} from '../styles/styles';
import {selectEditIcon, selectUserIcon} from '../utliz';
import {MdOutlineFeedback} from 'react-icons/md';

export const Quote = ({res, index, onPressEdit, checkFeedbacks}) => {
  return (
    <div key={index} className='h-[22rem] w-full '>
      <div className='flex pl-4 h-1/5'>
        <div
          className={`my-auto h-[2.8rem] w-[2.8rem] rounded-full ${
            res.live
              ? 'border-[#FF0000]'
              : index % 2 !== 0
              ? 'border-[#42C2A8]'
              : 'border-[#282B2B]'
          }  border-[2px] grid place-content-center`}
        >
          <img
            className='h-[1.5rem] self-center m-[4px]'
            src={selectUserIcon(res.live, index)}
            alt={SVG_LABELS.user}
          />
        </div>
        <p
          className={`w-[9rem] my-auto ml-1 text-sm font-semibold ${
            res.live
              ? 'text-[#FF0000]'
              : index % 2 !== 0
              ? 'text-[#42C2A8]'
              : 'text-black'
          }`}
        >
          {res.author}
        </p>
        {!isUndefined(res.feedback) && res.feedback && (
          <div
            className='grid place-content-center cursor-pointer ml-4'
            onClick={() => checkFeedbacks(res.id)}
          >
            <MdOutlineFeedback color='#CC7A00' size={34} className='mt-1' />
          </div>
        )}
        <div
          className={`grid place-content-center cursor-pointer ${
            !isUndefined(res.feedback) && !res.feedback
              ? 'ml-12'
              : LABELS.emptyString
          }`}
          onClick={() => onPressEdit(res)}
        >
          <img
            className='h-[1.5rem] self-center my-4 mx-2'
            src={selectEditIcon(res.live, index)}
            alt={SVG_LABELS.user}
          />
        </div>
      </div>
      <div className='h-4/5 pl-4'>
        <div
          style={styles.quoteBackground(res.live, index)}
          className='h-full shadow-md w-[17.5rem] rounded-xl relative'
        >
          <div style={styles.overlay} className='h-full w-full rounded-xl '>
            <div className='h-[9.6rem] w-full pt-5 px-6'>
              <p
                className={`h-full w-full border-t-2 border-x-2 px-3 py-1.5 ${
                  !isUndefined(res.quote) && res.quote.length >= 130
                    ? 'leading-4'
                    : 'leading-6'
                } tracking-tight border-white text-white ${
                  !isUndefined(res.quote) && res.quote.length >= 130
                    ? 'text-[11px]'
                    : 'text-[17px]'
                } rounded-t-lg`}
              >
                <p className='line-clamp-6'>{res.quote}</p>
              </p>
            </div>
            <div
              style={styles.darkOverlay}
              className=' w-full  h-[8rem] absolute bottom-0 rounded-b-[10px] rounded-t-[20px]'
            >
              <p className='text-xs text-white text-[9.5px] tracking-tight text-justify px-3 pt-2 leading-3 line-clamp-6'>
                {res.message}
              </p>
              <div className='h-8 rounded-b-lg absolute bottom-0 w-full flex'>
                <div className='w-4/6 flex'>
                  <div
                    className={`h-[1.2rem] ${
                      !isUndefined(res.author) && res.author.length >= 27
                        ? 'w-[1.6rem]'
                        : 'w-[1.2rem] '
                    } rounded-full border-2 my-auto ml-2 grid place-content-center`}
                  >
                    <img
                      className='h-[0.5rem] self-center'
                      src={require('../images/svg/white_user.svg').default}
                      alt={SVG_LABELS.whiteUser}
                    />
                  </div>
                  <p
                    className={`my-auto text-xs ml-1 text-white ${
                      !isUndefined(res.author) &&
                      res.author.length >= 22 &&
                      'text-[10px]'
                    }`}
                  >
                    {res.author}
                  </p>
                </div>
                <div className='w-2/6 flex justify-end'>
                  <p className='my-auto text-[9px] font-medium text-white'>
                    {res.live
                      ? THOUGHT_OF_THE_DAY.live
                      : moment(
                          !isNil(res.created_at)
                            ? res.created_at * 1000
                            : res.date
                        ).fromNow()}
                  </p>
                  <img
                    className='h-[0.9rem] self-center mr-2 ml-2'
                    src={require('../images/svg/clock.svg').default}
                    alt={SVG_LABELS.clock}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
