import moment from 'moment';
import React from 'react';
import {DASHBOARD_LABELS, SVG_LABELS, THOUGHT_OF_THE_DAY} from '../constants';
import {styles} from '../styles/styles';
import {selectEditIcon} from '../utliz';

export const Banner = ({item, index, onPressEdit}) => {
  return (
    <div>
      <div className='flex place-content-center h-1/5'>
        <div
          className={`cursor-pointer flex justify-end`}
          onClick={() => onPressEdit(item)}
        >
          <img
            className='h-[1.5rem] my-4 ml-72'
            src={selectEditIcon(item.live, index)}
            alt={SVG_LABELS.user}
          />
        </div>
      </div>
      <div className='place-content-center flex'>
        <div
          className={`w-[20rem] h-[18rem] ${
            item.live
              ? 'bg-[#7E0002]'
              : index % 2 !== 0
              ? 'bg-[#527972]'
              : 'bg-[#282B2B]'
          } rounded-t-xl  justify-between relative`}
        >
          <div
            style={styles.fromNowBgColor}
            className='h-10 rounded-t-xl shadow-xl flex justify-end'
          >
            <p className='my-auto text-xs ml-1 text-white'>
              {item.live
                ? THOUGHT_OF_THE_DAY.live
                : moment(item.created_at * 1000).fromNow()}
            </p>
            <img
              className='h-[0.9rem] self-center m-[4px] mr-4 ml-2'
              src={require('../images/svg/clock.svg').default}
              alt={SVG_LABELS.clock}
            />
          </div>
          <div className='h-[9.8rem] py-2 px-8'>
            <p className='text-white text-xl font-semibold'>Title:</p>
            <p className='my-2 text-sm text-white line-clamp-2'>{item.title}</p>
            <div className='flex'>
              <img
                className='h-[1.2rem] self-center'
                src={require('../images/svg/globe.svg').default}
                alt={DASHBOARD_LABELS.globe}
              />
              <p className='ml-2 text-xs underline text-white max-w-full float-none leading-4 line-clamp-3'>
                {item.hyperlink}
              </p>
            </div>
          </div>
          <div
            className='h-10 align-bottom absolute bottom-0 w-full flex
        border-l-[160px] border-l-transparent
        border-b-[90px] border-b-[#F5F5F5]
        border-r-[160px] border-r-transparent'
          />
        </div>
      </div>
    </div>
  );
};
