import {isEmpty, isNull} from "lodash";
import moment from "moment";
import {useCallback, useEffect, useState} from "react";
import {AddCounsoler} from "../components/AddCounsoler";
import {AddListing} from "../components/AddListing";
import {Alert} from "../components/Alert";
import {Counsellors} from "../components/Counsellors";
import {Listing} from "../components/Listing";
import {Loader} from "../components/Loader";
import Sidebar from "../components/Sidebar";
import {
  DASHBOARD_LABELS,
  ERROR_MESSAGES,
  LABELS,
  RESOURCES,
  RESOURCES_TABS,
  SIDEBAR_ACTIVE_NAME,
  SIDEBAR_SCREENS,
  VALIDATION_MESSAGES,
} from "../constants";
import {
  addAndEditListingData,
  addAndEditCounselorData,
  getResourceCounselor,
  getResourceListings,
} from "../services";
import {styles} from "../styles/styles";
import {checkValidUrl, validateEmail} from "../utliz";

function Resources() {
  const [toggleListingModal, setToggleListingModal] = useState(false);
  const [toggleCounselorModal, setToggleCounselorModal] = useState(false);
  const [openSideBar, setOpenSideBar] = useState(false);
  const [loader, setLoader] = useState(true);
  const [tabs, setTabs] = useState(JSON.parse(JSON.stringify(RESOURCES_TABS)));
  const [items, setItems] = useState([]);
  const [counsellorOrListing, setCounsellorOrListing] = useState(true);
  const [name, setName] = useState(LABELS.emptyString);
  const [email, setEmail] = useState(LABELS.emptyString);
  const [specialization, setSpecialization] = useState(LABELS.emptyString);
  const [education, setEducation] = useState(LABELS.emptyString);
  const [timezone, setTimezone] = useState(null);
  const [timezoneTitle, setTimezoneTitle] = useState(RESOURCES.timezone);
  const [mode, setMode] = useState(false);
  const [editCounselor, setEditCounselor] = useState(false);
  const [editListing, setEditListing] = useState(false);
  const [session, setSession] = useState(LABELS.emptyString);
  const [url, setUrl] = useState(LABELS.emptyString);
  const [hide, setHide] = useState(false);
  const [color, setColor] = useState("#1E5E90");
  const [icon, setIcon] = useState("2");
  const [showAlert, toggleAlert] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const toggleMenu = () => {
    setOpenSideBar((currentState) => !currentState);
  };

  const hideModal = () => {
    setToggleListingModal(false);
    setToggleCounselorModal(false);
    showAlert(false);
  };

  const selectedTab = (tab) => {
    setLoader(true);
    setItems([]);
    let tabName = tab.name.toLowerCase();
    let activeTab = {
      name: tab.name,
      id: tab.id,
      active: true,
    };
    setTabs((currentState) => {
      let preState = [...currentState];
      preState.forEach((item) => (item.active = false));
      let index = preState.findIndex((a) => a.id === tab.id);
      preState.splice(index, 1);
      preState = [...preState, activeTab];
      let newArray = preState.sort((a, b) => a.id - b.id);
      return newArray;
    });
    setLoader(false);
    setCounsellorOrListing(tabName === "counselors" ? true : false);
  };

  const addAndEditCounselor = (counselor = null) => {
    setToggleCounselorModal(true);
    if (!isNull(counselor)) {
      setSpecialization(counselor.specialization);
      setEmail(counselor.email);
      setEducation(counselor.education);
      setName(counselor.name);
      setHide(counselor.hide);
      setMode(counselor.mode);
      setTimezone(counselor.timezone);
      setEditCounselor(true);
      setTimezoneTitle(counselor.timezone.altName);
      setSelectedItem(counselor);
    } else {
      setSpecialization(LABELS.emptyString);
      setEmail(LABELS.emptyString);
      setEducation(LABELS.emptyString);
      setName(LABELS.emptyString);
      setHide(false);
      setMode(false);
      setTimezone(null);
      setEmail(LABELS.emptyString);
      setTimezoneTitle(RESOURCES.timezone);
      setEditCounselor(false);
      setSelectedItem(null);
    }
  };
  const addAndEditListing = (listing = null) => {
    setToggleListingModal(true);
    if (!isNull(listing)) {
      setSession(listing.session);
      setUrl(listing.url);
      setName(listing.name);
      setHide(listing.hide);
      setEditListing(true);
      setColor(listing.color);
      setIcon(listing.icon);
      setSelectedItem(listing);
    } else {
      setEditListing(false);
      setSession(LABELS.emptyString);
      setUrl(LABELS.emptyString);
      setName(LABELS.emptyString);
      setHide(false);
      setColor("#1E5E90");
      setIcon("2");
      setSelectedItem(null);
    }
  };

  const editExistingCounselor = async () => {
    try {
      setLoader(true);
      let validate = validateCounselorFields();
      if (validate) {
        let data = {
          created_at: selectedItem.created_at,
          hide: hide,
          mode,
          name,
          email,
          timezone,
          education,
          specialization,
          id: selectedItem.id,
        };
        let response = await addAndEditCounselorData(data);
        if (response.success) {
          fetchData();
          hideModal();
        } else {
          alert(ERROR_MESSAGES.somethingWentWrong);
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addNewCounselor = async () => {
    try {
      setLoader(true);
      let validate = validateCounselorFields();
      if (validate) {
        let data = {
          created_at: moment().unix(),
          hide: hide,
          mode,
          name,
          email,
          timezone,
          education,
          specialization,
        };
        let response = await addAndEditCounselorData(data);
        if (response.success) {
          fetchData();
          hideModal();
        } else {
          alert(ERROR_MESSAGES.somethingWentWrong);
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const addNewListing = async () => {
    try {
      setLoader(true);
      let validate = validateListingFields();
      if (validate) {
        let data = {
          created_at: moment().unix(),
          color,
          date_time: "",
          hide: hide,
          icon,
          name,
          session,
          url,
        };
        let response = await addAndEditListingData(data);
        if (response.success) {
          fetchData();
          hideModal();
        } else {
          alert(ERROR_MESSAGES.somethingWentWrong);
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const validateListingFields = () => {
    let validate = true;
    if (isEmpty(name.trim())) {
      alert(VALIDATION_MESSAGES.nameRequired);
      validate = false;
    } else if (isEmpty(session.trim())) {
      alert(VALIDATION_MESSAGES.sessionName);
      validate = false;
    } else if (isEmpty(url.trim())) {
      alert(VALIDATION_MESSAGES.emptyUrl);
      validate = false;
    } else if (!checkValidUrl(url)) {
      alert(VALIDATION_MESSAGES.invalidUrl);
      validate = false;
    }
    return validate;
  };

  const validateCounselorFields = () => {
    let validate = true;
    if (isEmpty(name.trim())) {
      alert(VALIDATION_MESSAGES.nameRequiredCounselor);
      validate = false;
    } else if (isEmpty(email.trim())) {
      alert(VALIDATION_MESSAGES.emailRequired);
      validate = false;
    } else if (isEmpty(education.trim())) {
      alert(VALIDATION_MESSAGES.educationRequired);
      validate = false;
    } else if (isEmpty(specialization.trim())) {
      alert(VALIDATION_MESSAGES.specializationRequired);
      validate = false;
    } else if (!validateEmail(email)) {
      alert(VALIDATION_MESSAGES.invalidEmail);
      validate = false;
    }
    return validate;
  };

  const editExistingListing = async () => {
    try {
      setLoader(true);
      let validate = validateListingFields();
      if (validate) {
        let data = {
          created_at: selectedItem.created_at,
          color,
          date_time: "",
          hide: hide,
          icon,
          name,
          session,
          url,
          id: selectedItem.id,
        };
        let response = await addAndEditListingData(data);
        if (response.success) {
          fetchData();
          hideModal();
        } else {
          alert(ERROR_MESSAGES.somethingWentWrong);
          setLoader(false);
        }
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const hideCounselor = (item) => {
    setName(item.name);
    setSelectedItem(item);
    toggleAlertModal();
  };

  const hideListing = (item) => {
    setName(item.name);
    setSelectedItem(item);
    toggleAlertModal();
  };

  const hidingListingItem = async () => {
    try {
      toggleAlertModal();
      setLoader(true);
      let data = {...selectedItem, hide: !selectedItem.hide};
      let response = await addAndEditListingData(data);
      if (response.success) {
        fetchData();
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const hidingCounselorItem = async () => {
    try {
      toggleAlertModal();
      setLoader(true);
      let data = {...selectedItem, hide: !selectedItem.hide};
      let response = await addAndEditCounselorData(data);
      if (response.success) {
        fetchData();
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const toggleAlertModal = () => {
    toggleAlert((currentState) => !currentState);
  };

  const fetchData = useCallback(async () => {
    try {
      setItems([]);
      setLoader(true);
      let response = counsellorOrListing
        ? await getResourceCounselor()
        : await getResourceListings();
      setItems(response.sort((a, b) => b.created_at - a.created_at));
    } catch (error) {
      console.log(error.message);
    }
    setLoader(false);
  }, [counsellorOrListing]);

  const setTimezoneValues = (timezone) => {
    setTimezone(timezone);
    setTimezoneTitle(timezone.altName);
  };

  useEffect(() => {
    SIDEBAR_ACTIVE_NAME.name = SIDEBAR_SCREENS.resources;
    fetchData();
  }, [fetchData]);

  return (
    <div className='overflow-hidden h-screen flex'>
      <Sidebar open={openSideBar} toggleMenu={toggleMenu} />
      <AddCounsoler
        show={toggleCounselorModal}
        closeModal={hideModal}
        addCounselor={addNewCounselor}
        editCounselor={editExistingCounselor}
        edit={editCounselor}
        name={name}
        email={email}
        education={education}
        specialization={specialization}
        mode={mode}
        setMode={setMode}
        setEmail={setEmail}
        setName={setName}
        setEducation={setEducation}
        setSpecialization={setSpecialization}
        hide={hide}
        setHide={setHide}
        timezoneTitle={timezoneTitle}
        selectedTimeZone={setTimezoneValues}
      />
      <AddListing
        show={toggleListingModal}
        closeModal={hideModal}
        addListing={addNewListing}
        editListing={editExistingListing}
        edit={editListing}
        name={name}
        setName={setName}
        session={session}
        url={url}
        setSession={setSession}
        setUrl={setUrl}
        hide={hide}
        color={color}
        setHide={setHide}
        setColor={setColor}
        setIcon={setIcon}
        icon={icon}
      />

      {showAlert && (
        <Alert
          handleClose={toggleAlertModal}
          title={"Confirmation"}
          message={`Are you sure you want to hide ${name}?`}
          cancelText={"No"}
          okayText={"Yes"}
          onPress={() =>
            counsellorOrListing ? hidingCounselorItem() : hidingListingItem()
          }
        />
      )}

      <div className='h-screen w-full px-[10px] md:px-[40px]'>
        <div className='flex mx-auto'>
          <Loader loading={loader} marginLeft={"96"} />
          <div style={styles.menuWidth} className='grid place-content-center'>
            <img
              className='h-[1.6rem] ml-5 md:hidden cursor-pointer'
              src={require("../images/svg/menu.svg").default}
              alt={DASHBOARD_LABELS.boxSvg}
              onClick={() => setOpenSideBar((currentState) => !currentState)}
            />
          </div>
          <div
            style={styles.addNewMeditationContainer}
            className='flex justify-end'
          >
            <button
              className='bg-[#24BB9C] h-[2rem] rounded-[7px]
           w-[9.2rem] text-white drop-shadow-lg text-[14px] flex content-center my-[20px]'
              onClick={() =>
                counsellorOrListing
                  ? addAndEditCounselor()
                  : addAndEditListing()
              }
            >
              <img
                className='h-[0.9rem] self-center m-[4px] mx-3'
                src={require("../images/svg/plus.svg").default}
                alt={DASHBOARD_LABELS.boxSvg}
              />
              <p className='self-center'>
                {counsellorOrListing
                  ? RESOURCES.addCounselor
                  : RESOURCES.addListing}
              </p>
            </button>
          </div>
        </div>
        <div className='pb-2 w-full h-[2.5rem] flex mt-4'>
          {tabs.map((res, index) => {
            return (
              <span
                onClick={() => selectedTab(res)}
                style={styles.tabsButton(res.active)}
                className='cursor-pointer rounded-[35px] w-[6rem] mx-[8px] sm:mx-[15px] px-[10px]  grid place-content-center'
                key={index}
              >
                <p className='text-sm font-medium'>{res.name}</p>
              </span>
            );
          })}
        </div>
        {isEmpty(items) && !loader && (
          <div className='grid place-items-center h-4/6 w-full'>
            <p className='text-lg font-semibold'>{LABELS.noItem}</p>
          </div>
        )}
        <div className=' container px-[15px] h-full mx-auto overflow-y-scroll pb-24'>
          <section>
            <div className='container py-10 mx-auto flex flex-wrap'>
              <div className='flex flex-wrap w-full'>
                <div className='grid grid-cols-1 lg:grid-cols-2  xl:grid-cols-3 gap-7 bg-[#F5F5F5] pb-4'>
                  {items.map((res, index) => {
                    return (
                      <div key={index} className='mt-2'>
                        {counsellorOrListing ? (
                          <Counsellors
                            res={res}
                            index={index}
                            hideCounselor={hideCounselor}
                            edit={addAndEditCounselor}
                          />
                        ) : (
                          <Listing
                            res={res}
                            index={index}
                            hideListing={hideListing}
                            edit={addAndEditListing}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Resources;
