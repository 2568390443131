import {isNil, isUndefined} from 'lodash';
import moment from 'moment';
import {SVG_LABELS, THOUGHT_OF_THE_DAY} from '../constants';
import {styles} from '../styles/styles';
import {
  imageEdit,
  imageFeedback,
  selectImageIcon,
  selectImageSmallIcon,
} from '../utliz';

export const ImageQuote = ({res, index, onPressEdit, checkFeedbacks}) => {
  const openUrl = () => {
    window.open(res.url, '_blank', 'noreferrer');
  };
  return (
    <div key={index} className='h-[22rem] w-full'>
      <div className='flex pl-4 h-1/5'>
        <div
          className={`my-auto h-[2.8rem] w-[2.8rem] rounded-full ${
            res.live ? 'border-[#FF0000]' : 'border-[#282B2B]'
          }  border-[3px] grid place-content-center`}
        >
          <div className='h-[1.8rem] self-center m-[4px]'>
            {selectImageIcon(res.live, index)}
          </div>
        </div>
        <p
          className={`w-[9rem] line-clamp-2 my-auto ml-1 text-sm font-semibold ${
            res.live ? 'text-[#FF0000]' : 'text-black'
          }`}
        >
          {res.title}
        </p>
        {!isUndefined(res.feedback) && res.feedback && (
          <div
            className='grid place-content-center cursor-pointer'
            onClick={() => checkFeedbacks(res.id)}
          >
            <img
              className='h-[2rem] self-center my-4 mx-2'
              src={imageFeedback(res.live)}
              alt={SVG_LABELS.feedback}
            />
          </div>
        )}
        <div
          className={`grid place-content-center cursor-pointer ${
            !isUndefined(res.feedback) && !res.feedback && 'ml-12'
          }`}
          onClick={() => onPressEdit(res)}
        >
          <img
            className='h-[1.5rem] self-center my-4 mx-2'
            src={imageEdit(res.live)}
            alt={SVG_LABELS.edit}
          />
        </div>
      </div>
      <div className='h-4/5 pl-4'>
        <div className='h-full shadow-md w-[17.5rem] rounded-xl relative'>
          <div style={styles.overlay} className='h-full w-full rounded-xl'>
            <div className='h-full w-full'>
              <img
                className='rounded-lg h-full w-full'
                src={res.image_url}
                alt='quote'
              />
            </div>

            <div
              style={styles.imageOverlay}
              className='w-full h-[5.4rem] absolute bottom-0 rounded-b-[10px] rounded-t-[20px]'
            >
              <span>
                <p
                  onClick={openUrl}
                  className='underline cursor-pointer text-[#88DEFF] text-[10px] tracking-tight text-justify px-3 pt-2 leading-3 line-clamp-6'
                >
                  {res.url}
                </p>
              </span>
              <div className=' h-8 rounded-b-lg absolute bottom-0 w-full flex'>
                <div className='w-4/6 flex'>
                  <div
                    className={`h-[1.2rem] ${
                      !isUndefined(res.title) && res.title.length >= 27
                        ? 'w-[1.6rem]'
                        : 'w-[1.2rem] '
                    } rounded-full border-2 my-auto ml-2 grid place-content-center`}
                  >
                    <div className='h-[0.5rem] self-center mb-1'>
                      {selectImageSmallIcon()}
                    </div>
                  </div>
                  <p
                    className={`my-auto text-xs ml-1 text-white ${
                      !isUndefined(res.title) &&
                      res.title.length >= 22 &&
                      'text-[10px]'
                    }`}
                  >
                    {res.title}
                  </p>
                </div>
                <div className='w-2/6 flex justify-end'>
                  <p className='my-auto text-[9px] font-medium text-white'>
                    {res.live
                      ? THOUGHT_OF_THE_DAY.live
                      : moment(
                          !isNil(res.created_at)
                            ? res.created_at * 1000
                            : res.date
                        ).fromNow()}
                  </p>
                  <img
                    className='h-[0.9rem] self-center mr-2 ml-2'
                    src={require('../images/svg/clock.svg').default}
                    alt={SVG_LABELS.clock}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
