import React from 'react';
import {CONTENT_CREDITS, LABELS} from '../constants';

export const EditCredits = ({
  show,
  closeModal,
  selectedContentName,
  selectedContentUrl,
  onChange,
  onChangeUrl,
  onPress,
}) => {
  return (
    <>
      {show ? (
        <div className='overflow-y-auto overflow-x-hidden fixed grid place-content-center z-50 w-full md:inset-0 h-modal md:h-full'>
          <div className=' p-4 w-96 h-full md:h-auto'>
            <div className='relative bg-white rounded-lg shadow dark:bg-gray-700'>
              <button
                onClick={closeModal}
                type='button'
                className='absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
                data-modal-toggle='authentication-modal'
              >
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'></path>
                </svg>
                <span className='sr-only'>{CONTENT_CREDITS.closeModal}</span>
              </button>
              <div className='py-6 px-6 lg:px-8'>
                <h3 className='mb-4 text-xl font-medium text-gray-900 dark:text-white'>
                  {CONTENT_CREDITS.editCredits}
                </h3>

                <div className='my-4'>
                  <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                    {CONTENT_CREDITS.credit}
                  </label>
                  <input
                    type='text'
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                    placeholder='Credits'
                    required
                    value={selectedContentName}
                    onChange={(e) => onChange(e.target.value)}
                  />
                </div>
                <div className='my-4'>
                  <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300'>
                    {CONTENT_CREDITS.url}
                  </label>
                  <input
                    type='text'
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                    placeholder='Credits'
                    required
                    value={selectedContentUrl}
                    onChange={(e) => onChangeUrl(e.target.value)}
                  />
                </div>

                <button
                  className='my-4 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                  onClick={onPress}
                >
                  {LABELS.edit}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
