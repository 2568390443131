import {useState, useRef} from 'react';
import {Loader} from '../components/Loader';
import Sidebar from '../components/Sidebar';
import {
  DASHBOARD_LABELS,
  ERROR_MESSAGES,
  HOME_SCREEN_BANNER,
  LABELS,
  SIDEBAR_ACTIVE_NAME,
  SIDEBAR_SCREENS,
  VALIDATION_MESSAGES,
} from '../constants';
import {styles} from '../styles/styles';
import React from 'react';
import {Banner} from '../components/Banner';
import {AddBanner} from '../components/AddBanner';
import {filter, first, isEmpty, isNull} from 'lodash';
import {useEffect} from 'react';
import {addAndEditBanners, getBanners} from '../services';
import moment from 'moment';

function HomeScreenBanner() {
  const [openSideBar, setOpenSideBar] = useState(false);
  const [loader, setLoader] = useState(true);
  const [showAndEditModal, toggleBannerModal] = useState(false);
  const [title, setTitle] = useState(LABELS.emptyString);
  const [link, setLink] = useState(LABELS.emptyString);
  const [live, setLive] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [banners, setBanners] = useState(LABELS.emptyArray);
  let addAndEdit = useRef();

  const toggleMenu = () => {
    setOpenSideBar((currentState) => !currentState);
  };

  const toggleModal = () => {
    toggleBannerModal((currentState) => !currentState);
  };

  const addAndEditBanner = (banner = null) => {
    if (!isNull(banner)) {
      addAndEdit.current = true;
      setSelectedBanner(banner);
      setLink(banner.hyperlink);
      setTitle(banner.title);
      setLive(banner.live);
      toggleModal();
    } else {
      addAndEdit.current = false;
      setSelectedBanner(null);
      setLink(LABELS.emptyString);
      setTitle(LABELS.emptyString);
      setLive(false);

      toggleModal();
    }
  };

  const getPrevLiveQuote = () => {
    let liveQuote = first(filter(banners, (a) => a.live));
    let quote = {...liveQuote, live: false};
    return quote;
  };

  const validateFields = () => {
    let validate = true;
    if (isEmpty(title.trim())) {
      alert(VALIDATION_MESSAGES.titleRequired);
      validate = false;
    } else if (isEmpty(link.trim())) {
      alert(VALIDATION_MESSAGES.emptyUrl);
      validate = false;
    }
    return validate;
  };

  const addNewBanner = async () => {
    try {
      toggleModal();
      setLoader(true);
      if (validateFields()) {
        let data = {
          hyperlink: link,
          title,
          live: live,
          created_at: moment().unix(),
        };
        checkLiveBanner(data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const editSelectedBanner = async () => {
    try {
      toggleModal();
      setLoader(true);
      if (validateFields()) {
        let data = {
          hyperlink: link,
          title,
          live: live,
          id: selectedBanner.id,
          created_at: selectedBanner.created_at,
        };
        checkLiveBanner(data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const checkLiveBanner = async (data) => {
    if (live) {
      let prevBanner = getPrevLiveQuote();
      const [response] = await Promise.all([
        addAndEditBanners(data),
        addAndEditBanners(prevBanner),
      ]);
      refreshAfterResponse(response);
    } else {
      let response = await addAndEditBanners(data);
      refreshAfterResponse(response);
    }
  };

  const refreshAfterResponse = (response) => {
    if (response.success) {
      fetchAllBanners();
    } else {
      alert(ERROR_MESSAGES.somethingWentWrong);
      setLoader(false);
    }
  };

  const fetchAllBanners = async () => {
    try {
      let response = await getBanners();
      setBanners(response.sort((a, b) => b.created_at - a.created_at));
      setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    SIDEBAR_ACTIVE_NAME.name = SIDEBAR_SCREENS.homeScreenBanner;
    fetchAllBanners();
  }, []);

  return (
    <div className='overflow-hidden h-screen flex'>
      <AddBanner
        show={showAndEditModal}
        closeModal={toggleModal}
        live={live}
        setLive={setLive}
        title={title}
        setTitle={setTitle}
        link={link}
        setLink={setLink}
        edit={addAndEdit.current}
        editBanner={editSelectedBanner}
        addBanner={addNewBanner}
      />
      <Sidebar open={openSideBar} toggleMenu={toggleMenu} />
      <div className='w-full px-[20px] md:px-[40px]'>
        <div className='mt-2 overflow-y-auto container h-full pb-10'>
          <div className='flex mx-auto '>
            <Loader loading={loader} marginLeft={'96'} />
            <div style={styles.menuWidth} className='grid place-content-center'>
              <img
                className='h-[1.6rem] ml-5 md:hidden cursor-pointer'
                src={require('../images/svg/menu.svg').default}
                alt={DASHBOARD_LABELS.boxSvg}
                onClick={() => setOpenSideBar((currentState) => !currentState)}
              />
            </div>
            <div
              style={styles.addNewMeditationContainer}
              className='flex justify-end'
            >
              <button
                className='bg-[#24BB9C] h-[2rem] rounded-[7px]
           w-[9rem] text-white drop-shadow-lg text-[14px] flex content-center my-[20px]'
                onClick={() => addAndEditBanner()}
              >
                <img
                  className='h-[0.9rem] self-center m-[4px] mx-3'
                  src={require('../images/svg/plus.svg').default}
                  alt={DASHBOARD_LABELS.boxSvg}
                />
                <p className='self-center font-semibold mb-[0.1px]'>
                  {HOME_SCREEN_BANNER.addBanner}
                </p>
              </button>
            </div>
          </div>
          <p className='text-2xl font-semibold py-4'>{'Home Screen Banners'}</p>
          <div className='grid grid-cols-1 lg:grid-cols-2  xl:grid-cols-3 gap-7 bg-[#F5F5F5] pb-4 pt-4'>
            {banners.map((res, index) => {
              return (
                <div key={index}>
                  <Banner
                    item={res}
                    index={index}
                    onPressEdit={addAndEditBanner}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeScreenBanner;
