import {sideBarIcon} from '../utliz';

export const SidebarButton = ({
  activeScreen,
  sidebarScreen,
  switchPage,
  navigationName,
}) => {
  return (
    <div
      className={`cursor-pointer flex ${
        activeScreen === sidebarScreen && 'bg-[#fff]'
      } h-[3em] pl-3 place-items-center`}
      onClick={() => switchPage(sidebarScreen, navigationName)}
    >
      {sideBarIcon(activeScreen === sidebarScreen, sidebarScreen)}
      <h2
        className={`${
          activeScreen === sidebarScreen ? 'text-[#45535E]' : 'text-white'
        } ml-4 text-lg`}
      >
        {sidebarScreen}
      </h2>
    </div>
  );
};
