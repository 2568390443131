import {
  DASHBOARD_LABELS,
  DASHBOARD_TABS,
  SIDEBAR_ACTIVE_NAME,
  SIDEBAR_SCREENS,
} from '../constants';
import Sidebar from '../components/Sidebar';
import {useCallback, useState} from 'react';
import {styles} from '../styles/styles';
import {useNavigate} from 'react-router-dom';
import {Alert} from '../components/Alert';
import {useEffect} from 'react';
import {fetchMeditationList} from '../services';
import {Loader} from '../components/Loader';
import {cloneDeep, isEmpty} from 'lodash';

export default function Dashboard() {
  const [openSideBar, setOpenSideBar] = useState(false);
  const [tabs, setTabs] = useState(cloneDeep(DASHBOARD_TABS));
  const [loader, setLoader] = useState(true);
  const [items, setItems] = useState([]);
  const navigation = useNavigate();
  const [showModal, toggleModal] = useState(false);
  const alertTitle = useState('');
  const alertMessage = useState('');

  const handleClose = () => {
    toggleModal(false);
  };

  const addNewMeditationMedia = () => {
    navigation('/create-meditation');
  };

  const selectedTab = (tab) => {
    setLoader(true);
    setItems([]);
    let tabName = tab.name.toLowerCase();
    let activeTab = {
      name: tab.name,
      id: tab.id,
      active: true,
    };
    setTabs((currentState) => {
      let preState = [...currentState];
      preState.forEach((item) => (item.active = false));
      let index = preState.findIndex((a) => a.id === tab.id);
      preState.splice(index, 1);
      preState = [...preState, activeTab];
      let newArray = preState.sort((a, b) => a.id - b.id);
      return newArray;
    });
    fetchMedia(tabName);
  };

  const toggleMenu = () => {
    setOpenSideBar((currentState) => !currentState);
  };

  const fetchMedia = useCallback(async (media = '') => {
    let list = await fetchMeditationList(media);
    setItems(list);
    setLoader(false);
  }, []);

  useEffect(() => {
    SIDEBAR_ACTIVE_NAME.name = SIDEBAR_SCREENS.dashboard;
    fetchMedia('guided');
  }, [fetchMedia]);

  return (
    <>
      {showModal && (
        <Alert
          handleClose={handleClose}
          title={alertTitle}
          message={alertMessage}
          cancelText={'Cancel'}
          okayText={'Okay'}
        />
      )}
      <div className='overflow-hidden h-screen flex'>
        <Sidebar open={openSideBar} toggleMenu={toggleMenu} />
        <div className='h-screen w-full px-[10px] md:px-[40px]'>
          <div className='flex mx-auto '>
            <Loader loading={loader} marginLeft={'96'} />
            <div style={styles.menuWidth} className='grid place-content-center'>
              <img
                className='h-[1.6rem] ml-5 md:hidden cursor-pointer'
                src={require('../images/svg/menu.svg').default}
                alt={DASHBOARD_LABELS.boxSvg}
                onClick={() => setOpenSideBar((currentState) => !currentState)}
              />
            </div>
            <div
              style={styles.addNewMeditationContainer}
              className='flex justify-end'
            >
              <button
                className='bg-[#24BB9C] h-[2rem] rounded-[7px]
           w-[13rem] text-white drop-shadow-lg text-[14px] flex content-center my-[20px]'
                onClick={addNewMeditationMedia}
              >
                <img
                  className='h-[0.9rem] self-center m-[4px] mx-3'
                  src={require('../images/svg/plus.svg').default}
                  alt={DASHBOARD_LABELS.boxSvg}
                />
                <p className='self-center'>
                  {DASHBOARD_LABELS.createNewMeditation}
                </p>
              </button>
            </div>
          </div>
          <div className='pb-2 w-full h-[2.5rem] flex mt-4'>
            {tabs.map((res, index) => {
              return (
                <span
                  onClick={() => selectedTab(res)}
                  style={styles.tabsButton(res.active)}
                  className='cursor-pointer rounded-[35px] w-[5.2rem] mx-[8px] sm:mx-[15px] px-[10px]  grid place-content-center'
                  key={index}
                >
                  <p className='text-sm font-medium'>{res.name}</p>
                </span>
              );
            })}
          </div>
          {isEmpty(items) && !loader && (
            <div className='grid place-items-center h-4/6 w-full'>
              <p className='text-lg font-semibold'>No items found</p>
            </div>
          )}
          <div className='container px-[15px] h-full mx-auto overflow-y-scroll pb-24'>
            <section>
              <div className='container py-10 mx-auto flex flex-wrap'>
                <div className='flex flex-wrap w-full'>
                  {items.map((res, index) => {
                    return (
                      <div key={index} className=' md:p-4 p-1 lg:w-1/2 w-full'>
                        <div style={styles.backgroundImg(res.thumbnail)}>
                          <div
                            className='flex p-2 sm:p-6 rounded-lg'
                            style={styles.transparentBackground}
                          >
                            <div className='w-14 sm:h-20 sm:mr-8 sm:mb-0 inline-flex items-center justify-center rounded-full flex-shrink-0'>
                              <img
                                className={`h-[${
                                  res.type === 'audio' ? '1.8rem' : '2.2rem'
                                }] self-center cursor-pointer`}
                                src={
                                  require(res.type === 'audio'
                                    ? '../images/svg/music.svg'
                                    : '../images/svg/play.svg').default
                                }
                                alt={DASHBOARD_LABELS.boxSvg}
                              />
                            </div>
                            <div className='flex-grow overflow-hidden w-24'>
                              <h2 className='text-white text-lg title-font font-medium mb-1 truncate mt-4 sm:mt-2'>
                                {res.title}
                              </h2>
                              <h2 className='text-white text-md title-font font-medium'>
                                {res.time}
                              </h2>
                            </div>
                            <div className='w-10 h-20 sm:ml-4 sm:mb-0 mb-4 inline-flex justify-center rounded-full  flex-shrink-0'></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}
