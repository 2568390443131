import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import CreateMeditation from './pages/CreateMeditation';
import Credits from './pages/Credits';
import ThoughtOfTheDay from './pages/ThoughtOfTheDay';
import {useContext} from 'react';
import {AuthContext} from './context/AuthContext';
import HomeScreenBanner from './pages/HomeScreenBanner';
import Resources from './pages/Resources';

function App() {
  const {currentUser} = useContext(AuthContext);

  const RequireAuth = ({children}) => {
    return currentUser ? children : <Navigate to='/' />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route
          path='/dashboard'
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path='/create-meditation'
          element={
            <RequireAuth>
              <CreateMeditation />
            </RequireAuth>
          }
        />
        <Route
          path='/credits'
          element={
            <RequireAuth>
              <Credits />
            </RequireAuth>
          }
        />
        <Route
          path='/thought-of-day'
          element={
            <RequireAuth>
              <ThoughtOfTheDay />
            </RequireAuth>
          }
        />
        <Route
          path='/home-screen-banner'
          element={
            <RequireAuth>
              <HomeScreenBanner />
            </RequireAuth>
          }
        />
        <Route
          path='/resources'
          element={
            <RequireAuth>
              <Resources />
            </RequireAuth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
