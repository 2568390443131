import React from 'react';
import {ThreeCircles} from 'react-loader-spinner';

export const Loader = ({loading, marginLeft = '0'}) => {
  return loading ? (
    <div
      id='defaultModal'
      className='fixed z-50 w-full md:inset-0 h-modal md:h-full'
    >
      <div className={`grid place-content-center h-screen `}>
        <div className={`ml-0 md:ml-${marginLeft}`}>
          <ThreeCircles
            height='100'
            width='100'
            color='#42525F'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
            ariaLabel='three-circles-rotating'
            outerCircleColor=''
            innerCircleColor=''
            middleCircleColor=''
          />
        </div>
      </div>
    </div>
  ) : null;
};
