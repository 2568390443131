import React from "react";
import {
  CONTENT_CREDITS,
  DASHBOARD_LABELS,
  HOME_SCREEN_BANNER,
  RESOURCES,
  TIMEZONES,
} from "../constants";
import {styles} from "../styles/styles";
import {Dropdown} from "../components/DropdownTimezone";
import {first} from "lodash";

export const AddCounsoler = ({
  show,
  closeModal,
  edit = false,
  name,
  setName,
  email,
  setEmail,
  education,
  setEducation,
  specialization,
  setSpecialization,
  mode,
  setMode,
  hide,
  setHide,
  editCounselor,
  addCounselor,
  selectedTimeZone,
  timezoneTitle,
}) => {
  const selecTimezone = (type) => {
    let selectedZone = first(TIMEZONES.filter((a) => a.name === type));
    selectedTimeZone(selectedZone.value);
  };

  return (
    <>
      {show ? (
        <div
          style={styles.addQuoteBgColor}
          className='overflow-y-auto overflow-x-hidden fixed grid place-content-center z-50 w-full md:inset-0 h-modal md:h-full'
        >
          <div className='p-4 w-96 h-full md:h-auto'>
            <div className='relative bg-white rounded-lg shadow dark:bg-[#758A9B]'>
              <button
                onClick={closeModal}
                type='button'
                className='absolute top-3 right-2.5 text-black dark:text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white'
                data-modal-toggle='authentication-modal'
              >
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'></path>
                </svg>
                <span className='sr-only'>{CONTENT_CREDITS.closeModal}</span>
              </button>
              <div className='py-6 px-6 lg:px-8'>
                <h3 className='mb-4 text-xl font-medium text-gray-900 dark:text-white'>
                  {edit ? RESOURCES.editCounselor : RESOURCES.addCounselor}
                </h3>

                <div className='my-4'>
                  <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                    {RESOURCES.name}
                  </label>
                  <input
                    type='text'
                    className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className='my-4'>
                  <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                    {RESOURCES.email}
                  </label>
                  <input
                    type='text'
                    className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className='my-4'>
                  <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                    {RESOURCES.education}
                  </label>
                  <input
                    type='text'
                    className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                    required
                    value={education}
                    onChange={(e) => setEducation(e.target.value)}
                  />
                </div>

                <div className='my-4'>
                  <label className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'>
                    {RESOURCES.specialization}
                  </label>
                  <input
                    type='text'
                    className='focus:outline-none bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-[#758A9B] dark:border-white dark:placeholder-gray-400 dark:text-white'
                    required
                    value={specialization}
                    onChange={(e) => setSpecialization(e.target.value)}
                  />
                </div>

                <div className='flex justify-between mt-4'>
                  <Dropdown
                    width={"96"}
                    title={timezoneTitle}
                    onPress={selecTimezone}
                    list={TIMEZONES}
                  />
                </div>

                <div className='my-4'>
                  <label className='inline-flex relative items-center cursor-pointer'>
                    <input
                      type='checkbox'
                      checked={mode}
                      id='default-toggle'
                      className='sr-only peer'
                      onChange={(e) => setMode(e.target.checked)}
                    />
                    <div className="w-11 h-6 bg-gray-200  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-300"></div>
                    <span className='ml-3 text-sm font-medium text-gray-900 dark:text-white'>
                      {RESOURCES.mode}
                    </span>
                  </label>
                  <label className='inline-flex relative items-center cursor-pointer ml-2'>
                    <input
                      type='checkbox'
                      checked={hide}
                      id='default-toggle'
                      className='sr-only peer'
                      onChange={(e) => setHide(e.target.checked)}
                    />
                    <div className="w-11 h-6 bg-gray-200  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-red-600"></div>
                    <span className='ml-3 text-sm font-medium text-gray-900 dark:text-white'>
                      {RESOURCES.hideCounselor}
                    </span>
                  </label>
                </div>

                <button
                  onClick={edit ? editCounselor : addCounselor}
                  className='text-base flex place-content-center mt-8 mb-2 w-4/5 mx-auto text-white focus:ring-4 focus:outline-none font-medium rounded-lg px-5 py-2.5 text-center dark:bg-[#45535E] bg-[#45535E]'
                >
                  <p className='ml-8'>
                    {edit ? HOME_SCREEN_BANNER.edit : HOME_SCREEN_BANNER.create}
                  </p>
                  <img
                    className='h-[1rem] self-center ml-8'
                    src={require("../images/svg/plusIcon.svg").default}
                    alt={DASHBOARD_LABELS.boxSvg}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
