import {filter, first, isEmpty, isNull, isUndefined} from 'lodash';
import moment from 'moment';
import {useEffect} from 'react';
import {useState} from 'react';
import {AddQuotes} from '../components/AddQuotes';
import {Feedbacks} from '../components/Feedbacks';
import {ImageQuote} from '../components/ImageQuote';
import {Loader} from '../components/Loader';
import {Quote} from '../components/Quote';
import Sidebar from '../components/Sidebar';
import {
  DASHBOARD_LABELS,
  DATE_FORMAT,
  ERROR_MESSAGES,
  LABELS,
  MEDIA_TYPE,
  SIDEBAR_ACTIVE_NAME,
  SIDEBAR_SCREENS,
  THOUGHT_OF_THE_DAY,
  VALIDATION_MESSAGES,
} from '../constants';
import {addAndEditQuotes, getFeedbacks, getQuotes} from '../services';
import {styles} from '../styles/styles';
import {v4 as uuidv4} from 'uuid';
import {ref, getDownloadURL, uploadBytes} from 'firebase/storage';
import {storage} from '../firebase-config';

function ThoughtOfTheDay() {
  const [openSideBar, setOpenSideBar] = useState(false);
  const [loader, setLoader] = useState(true);
  const [quotes, setQuotes] = useState([]);
  const [selectedContent, setSelectedContent] = useState(null);
  const [editModal, showEditModal] = useState(false);
  const [live, setLive] = useState(false);
  const [name, setName] = useState(LABELS.emptyString);
  const [date, setDate] = useState(LABELS.emptyString);
  const [quote, setQuote] = useState(LABELS.emptyString);
  const [message, setMessage] = useState(LABELS.emptyString);
  const [addOrEdit, setAddOrEdit] = useState(false);
  const [feedbackList, setFeedbackList] = useState(LABELS.emptyArray);
  const [showFeedbackModal, toggleFeedbackModal] = useState(false);
  const [quoteOrImage, setQuoteOrImage] = useState(true);
  const [inputImage, setInputImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [title, setTitle] = useState(LABELS.emptyString);
  const [url, setUrl] = useState(LABELS.emptyString);

  const toggleMenu = () => {
    setOpenSideBar((currentState) => !currentState);
  };

  const fetchAllQuotes = async () => {
    try {
      let response = await getQuotes();
      setQuotes(response.sort((a, b) => moment(b.date) - moment(a.date)));
      setLoader(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const hideModal = () => {
    showEditModal(false);
    setInputImage(null);
  };

  const addAndEditQuote = (thought = null) => {
    if (!isNull(thought)) {
      showEditModal(true);
      setSelectedContent(thought);
      setName(thought.author);
      setDate(thought.date);
      setMessage(thought.message);
      setQuote(thought.quote);
      setAddOrEdit(true);
      setLive(thought.live);
      setInputImage(thought.image_url);
      !isUndefined(thought.image_url)
        ? setQuoteOrImage(false)
        : setQuoteOrImage(true);
      setTitle(thought.title);
      setUrl(thought.url);
    } else {
      setSelectedContent(thought);
      setName(LABELS.emptyString);
      setDate(LABELS.emptyString);
      setMessage(LABELS.emptyString);
      setQuote(LABELS.emptyString);
      setSelectedContent({add: true});
      showEditModal(true);
      setAddOrEdit(false);
      setLive(false);
      setQuoteOrImage(true);
      setTitle(LABELS.emptyString);
      setUrl(LABELS.emptyString);
    }
  };

  const validateFields = () => {
    let validate = true;
    if (isEmpty(name.trim())) {
      alert(VALIDATION_MESSAGES.nameRequired);
      validate = false;
    } else if (isEmpty(quote.trim())) {
      alert(VALIDATION_MESSAGES.quoteRequired);
      validate = false;
    } else if (isEmpty(message.trim())) {
      alert(VALIDATION_MESSAGES.messageRequired);
      validate = false;
    }
    return validate;
  };

  const addNewQuote = async () => {
    try {
      setLoader(true);
      if (!quoteOrImage) {
        if (!validateFieldsForImage()) {
          return;
        }
        let image_url = !isNull(imageFile)
          ? await uploadQuoteImage()
          : LABELS.emptyString;

        if (validateImage(image_url)) {
          let data = {
            date: isEmpty(date)
              ? moment(new Date()).format(DATE_FORMAT.american)
              : date,
            image_url,
            live: live,
            feedback: false,
            title: title,
            url: url,
            created_at: moment().unix(),
          };
          checkLiveQuote(data);
        }
      } else if (validateFields()) {
        let data = {
          author: name,
          date: isEmpty(date)
            ? moment(new Date()).format(DATE_FORMAT.american)
            : date,
          message: message,
          quote: quote,
          live: live,
          feedback: false,
          created_at: moment().unix(),
        };
        checkLiveQuote(data);
      } else {
        setLoader(false);
      }
      hideModal();
    } catch (error) {
      console.log(error.message);
    }
  };

  const validateImage = (image_url) => {
    let validate = true;
    if (isEmpty(image_url)) {
      alert(VALIDATION_MESSAGES.imageRequired);
      setLoader(false);
      validate = false;
    }
    return validate;
  };

  const validateFieldsForImage = () => {
    let validate = true;
    if (isEmpty(title.trim())) {
      alert(VALIDATION_MESSAGES.titleRequired);
      setLoader(false);
      validate = false;
    }
    return validate;
  };

  const editQuote = async () => {
    try {
      setLoader(true);
      if (!quoteOrImage) {
        if (!validateFieldsForImage()) {
          return;
        }
        let image_url = !isNull(imageFile)
          ? await uploadQuoteImage()
          : selectedContent.image_url;
        if (validateImage(image_url)) {
          let data = {
            date: isEmpty(date)
              ? moment(new Date()).format(DATE_FORMAT.american)
              : date,
            image_url,
            id: selectedContent.id,
            live: live,
            feedback: selectedContent.feedback,
            title: title,
            url: url,
            created_at: selectedContent?.created_at,
          };
          checkLiveQuote(data);
        }
      } else if (validateFields()) {
        let data = {
          author: name,
          date: date,
          message: message,
          quote: quote,
          id: selectedContent.id,
          live: live,
          feedback: selectedContent.feedback,
          created_at: selectedContent?.created_at,
        };
        checkLiveQuote(data);
      } else {
        setLoader(false);
      }
      hideModal();
    } catch (error) {
      console.log(error.message);
    }
  };

  const checkLiveQuote = async (data) => {
    if (live) {
      let prevQuote = getPrevLiveQuote();
      const [response] = await Promise.all([
        addAndEditQuotes(data),
        addAndEditQuotes(prevQuote),
      ]);
      refreshAfterResponse(response);
    } else {
      let response = await addAndEditQuotes(data);
      refreshAfterResponse(response);
    }
  };

  const getPrevLiveQuote = () => {
    let liveQuote = first(filter(quotes, (a) => a.live));
    let quote = {...liveQuote, live: false};
    return quote;
  };

  const refreshAfterResponse = (response) => {
    if (response.success) {
      fetchAllQuotes();
    } else {
      alert(ERROR_MESSAGES.somethingWentWrong);
      setLoader(false);
    }
  };

  const hideFeedbackModal = () => {
    toggleFeedbackModal((currentState) => !currentState);
  };

  const getFeedbacksList = async (id) => {
    try {
      setLoader(true);
      let response = await getFeedbacks(id);
      if (!isEmpty(response)) {
        setFeedbackList(response);
        toggleFeedbackModal(true);
      } else {
        toggleFeedbackModal(true);
        setFeedbackList(LABELS.emptyArray);
      }
    } catch (error) {
      console.log(error.message);
    }
    setLoader(false);
  };

  const uploadQuoteImage = async () => {
    try {
      let storageType = `${MEDIA_TYPE.quote}`;
      const uniqueId = uuidv4();
      let imageRef = `${storageType}/${uniqueId}-${imageFile.name}`;
      if (!isNull(imageFile)) {
        const posterReference = ref(storage, imageRef);
        uploadBytes(posterReference, imageFile);
        let snapshot = await uploadBytes(posterReference, imageFile);
        let downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
      }
    } catch (error) {
      console.log('Uploading photo error ', error.message);
    }
  };

  const imageBrowser = (event) => {
    if (event.target.files[0].size > 1597152) {
      alert(THOUGHT_OF_THE_DAY.imageSize);
      return;
    }
    const imageFile = first(event.target.files);
    setImageFile(imageFile);
    handleFileChange(event);
  };

  const handleFileChange = (event) => {
    const {target} = event;
    const {files} = target;
    if (files && first(files)) {
      var reader = new FileReader();
      reader.onload = (event) => {
        setInputImage(event.target.result);
      };
      reader.readAsDataURL(first(files));
    }
  };

  useEffect(() => {
    SIDEBAR_ACTIVE_NAME.name = SIDEBAR_SCREENS.thoughtOfTheDay;
    fetchAllQuotes();
  }, []);

  return (
    <div className='overflow-hidden h-screen flex'>
      {!isNull(selectedContent) && (
        <AddQuotes
          show={editModal}
          closeModal={hideModal}
          edit={addOrEdit}
          name={name}
          setName={setName}
          date={date}
          setDate={setDate}
          quote={quote}
          setQuote={setQuote}
          message={message}
          setMessage={setMessage}
          addQuote={addNewQuote}
          editQuote={editQuote}
          live={live}
          setLive={setLive}
          activeTab={quoteOrImage}
          setCurrentTab={setQuoteOrImage}
          selectImage={imageBrowser}
          quoteImage={inputImage}
          title={title}
          setTitle={setTitle}
          url={url}
          setUrl={setUrl}
        />
      )}
      {
        <Feedbacks
          show={showFeedbackModal}
          feedbacks={feedbackList}
          closeModal={hideFeedbackModal}
        />
      }
      <Sidebar open={openSideBar} toggleMenu={toggleMenu} />
      <div className='w-full px-[20px] md:px-[40px] '>
        <div className='mt-2 overflow-y-auto container h-full pb-10'>
          <div className='flex mx-auto '>
            <Loader loading={loader} marginLeft={'96'} />
            <div style={styles.menuWidth} className='grid place-content-center'>
              <img
                className='h-[1.6rem] ml-5 md:hidden cursor-pointer'
                src={require('../images/svg/menu.svg').default}
                alt={DASHBOARD_LABELS.boxSvg}
                onClick={() => setOpenSideBar((currentState) => !currentState)}
              />
            </div>
            <div
              style={styles.addNewMeditationContainer}
              className='flex justify-end'
            >
              <button
                className='bg-[#24BB9C] h-[2rem] rounded-[7px]
           w-[9rem] text-white drop-shadow-lg text-[14px] flex content-center my-[20px]'
                onClick={() => addAndEditQuote()}
              >
                <img
                  className='h-[0.9rem] self-center m-[4px] mx-3'
                  src={require('../images/svg/plus.svg').default}
                  alt={DASHBOARD_LABELS.boxSvg}
                />
                <p className='self-center'>{THOUGHT_OF_THE_DAY.createQuote}</p>
              </button>
            </div>
          </div>
          <p className='text-2xl font-semibold py-4'>
            {THOUGHT_OF_THE_DAY.quotesList}
          </p>
          <div className='grid grid-cols-1 lg:grid-cols-2  xl:grid-cols-3 gap-7 bg-[#F5F5F5] pb-4'>
            {quotes.map((res, index) => {
              return (
                <div key={index}>
                  {isUndefined(res.image_url) ? (
                    <Quote
                      key={index}
                      res={res}
                      index={index}
                      onPressEdit={addAndEditQuote}
                      checkFeedbacks={getFeedbacksList}
                    />
                  ) : (
                    <ImageQuote
                      key={index}
                      res={res}
                      index={index}
                      onPressEdit={addAndEditQuote}
                      checkFeedbacks={getFeedbacksList}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThoughtOfTheDay;
