import Resizer from 'react-image-file-resizer';
import {COLORS, LABELS, SIDEBAR_SCREENS} from '../constants';

import {AiOutlineHome} from 'react-icons/ai';
import {MdContentPaste, MdDomainVerification} from 'react-icons/md';
import {BsChatRightQuote} from 'react-icons/bs';
import {RiDatabase2Line} from 'react-icons/ri';
import {IoMdImage} from 'react-icons/io';

export const validateEmail = (email) => {
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const authenticationError = (error) => {
  let errorCode = error.code;
  let errorMessage = error.message;
  if (errorCode === 'auth/wrong-password') {
    alert('Incorrect password');
  } else if (errorCode === 'auth/user-not-found') {
    alert('Invalid user credentials');
  } else {
    alert(errorMessage);
  }
  console.log(error);
};

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      600,
      200,
      'PNG',
      0,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      600
    );
  });

export const toHHMMSS = (sec_num) => {
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;
  hours = hours < 10 ? LABELS.zero + hours : hours;
  minutes = minutes < 10 ? LABELS.zero + minutes : minutes;
  seconds = seconds < 10 ? LABELS.zero + seconds : seconds;
  let time =
    minutes +
    ':' +
    seconds +
    ` ${Number(minutes) === 0 ? LABELS.secs : LABELS.mins}`;
  return time;
};

export const checkForLinkBreak = (text) => {
  text = String(text).replace(/\\n\\n/g, '\n\n');
  return text;
};

export const checkValidUrl = (url) => {
  const regex = new RegExp(
    '(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
  );
  return regex.test(url);
};

export const selectUserIcon = (live, index) => {
  let userIcon = live
    ? require('../images/svg/red_user.svg').default
    : index % 2 !== 0
    ? require('../images/svg/user.svg').default
    : require('../images/svg/black_user.svg').default;
  return userIcon;
};

export const selectFeedbackIcon = (live, index) => {
  let feedbackIcon = live
    ? require('../images/svg/red-feedback.svg').default
    : index % 2 !== 0
    ? require('../images/svg/green-feedback.svg').default
    : require('../images/svg/black-feedback.svg').default;
  return feedbackIcon;
};

export const selectEditIcon = (live, index) => {
  let feedbackIcon = live
    ? require('../images/svg/red_edit.svg').default
    : index % 2 !== 0
    ? require('../images/svg/green_edit.svg').default
    : require('../images/svg/black_edit.svg').default;
  return feedbackIcon;
};

export const imageFeedback = (live) => {
  let feedback = live
    ? require('../images/svg/red-feedback.svg').default
    : require('../images/svg/black-feedback.svg').default;
  return feedback;
};

export const imageEdit = (live) => {
  let edit = live
    ? require('../images/svg/red_edit.svg').default
    : require('../images/svg/black_edit.svg').default;
  return edit;
};

export const activeTabStyle = (activeTab) => {
  let tab = activeTab
    ? 'text-white bg-[#45535E]'
    : 'dark:text-white text-black hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-[#6e8191] dark:hover:text-white';
  return tab;
};

export const selectIconColor = (index) => {
  return index % 2 !== 0 ? COLORS.aquamarine : COLORS.white;
};

export const sideBarIcon = (activeButton, screen) => {
  if (screen === SIDEBAR_SCREENS.dashboard) {
    return (
      <AiOutlineHome
        color={activeButton ? COLORS.black : COLORS.white}
        size={24}
      />
    );
  } else if (screen === SIDEBAR_SCREENS.credits) {
    return (
      <MdContentPaste
        color={activeButton ? COLORS.black : COLORS.white}
        size={24}
      />
    );
  } else if (screen === SIDEBAR_SCREENS.thoughtOfTheDay) {
    return (
      <BsChatRightQuote
        color={activeButton ? COLORS.black : COLORS.white}
        size={24}
      />
    );
  } else if (screen === SIDEBAR_SCREENS.homeScreenBanner) {
    return (
      <MdDomainVerification
        color={activeButton ? COLORS.black : COLORS.white}
        size={24}
      />
    );
  } else if (screen === SIDEBAR_SCREENS.resources) {
    return (
      <RiDatabase2Line
        color={activeButton ? COLORS.black : COLORS.white}
        size={24}
      />
    );
  }
};

export const selectImageIcon = (live) => {
  if (live) {
    return <IoMdImage color={COLORS.red} size={30} />;
  } else {
    return <IoMdImage color={COLORS.black} size={30} />;
  }
};

export const selectImageSmallIcon = () => {
  return <IoMdImage color={COLORS.white} size={13} />;
};
